export const ActionTypes = {
    PROCESS_NAVBAR: 'process navbar',
    FETCH_USER_DATA: 've data',
    FAIL_REQUEST: 'fail request',
    FETCH_CARD_INVENTORY: 'fetch card inventory',
    FETCH_ENROLLMENT_FORM: 'fetch enrollment form',
    FETCHING_ENROLLMENT_FORM: 'fetching enrollment form',
    PRE_FETCHING_ENROLLMENT_FORM: 'pre fetching enrollment form',
    FAIL_ENROLLMENT_REQUEST: 'fail enrollment form request',
    SUBMITTING_ENROLLMENT_FORM: 'submitting enrollment form',
    SUBMIT_ENROLLMENT_FORM: 'submit enrollment form',
    FAIL_ENROLLMENT_SUBMISSION: 'fail enrollment submission',
    FETCHING_HOME_DATA: 'fetching home data',
    FETCHING_CARD_DATA: 'fetching card data',
    FETCHING_CARD_INVENTORY_DATA: 'fetching card inventory data',
    FETCHING_VE_RANKING_DATA: 'fetching ve ranking data',
    FETCH_VE_RANKING_DATA: 'fetch ve ranking data',
    FAIL_VE_RANKING_DATA: 'fail ve ranking data',
    PRE_FETCHING_VE_RANKING_DATA: 'prefetch fetching ve ranking data',
    FETCH_CARD_CLIENT_LIST: 'fetch card client list',
    OPENING_VILLAGE_PAGE: 'opening village page',
    FETCHING_VE_DEPOSIT_DATA: 'fetching ve deposit data',
    FETCH_VE_DEPOSIT_DATA: 'fetch ve deposit data',
    FAIL_VE_DEPOSIT_DATA: 'fail ve deposit data',
    FETCHING_USER_PERMISSIONS: 'fetching user permissions',
    FETCH_USER_PERMISSIONS: 'fetch user permissions',
    FAIL_USER_PERMISSIONS: 'fail user permissions',
    SUBMITTING_VISIT_FORM: 'submitting visit form',
    SUBMIT_VISIT_FORM: 'submit visit form',
    FAIL_VISIT_FORM: 'fail visit form',
    SET_SELECTION_VILLAGE_URL: 'set selection village url',
    CLEAR_SELECTION_VILLAGE_INFO: 'clear selection village info',
    RECORD_INTERACTION_SUCCESS: 'record interaction success',
    RECORD_INTERACTION_FAILURE: 'record interaction failure',
    FETCH_INTERACTIONS_SUCCESS: 'fetch interactions success',
    FETCH_INTERACTIONS_FAILURE: 'fetch interactions failture',
    FETCHING_INTERACTIONS: 'fetching interactions',
    GET_INTERACTIONS_FAILURE: 'get interactions failure',
    GET_INTERACTIONS_SUCCESS: 'get interactions success',
    FETCH_INDEX_DB_CLIENT: 'fetch index db client',
    FETCHING_TRANSACTIONS: 'fetching transactions',
    FETCH_TRANSACTIONS_SUCCESS: 'fetch transactions success',
    FETCH_TRANSACTIONS_FAILURE: 'fetch transactions failure',
    FETCH_TRANSACTIONS_COMPLETE: 'fetch transactions complete',
    MULTIPLE_VILLAGE_COUNT: 'multiple village count'
}
