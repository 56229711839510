import React, { useCallback } from 'react'
import { colors } from 'styles/colors'
import './CircularProgressBar.scss'
import { ThunderIcon } from 'assets'

const DEFAULT_FONT_SIZE = 14
const DEFAULT_LINE_HEIGHT = 1.5
const DEFAULT_PROGRESS_BAR_SIZE = 150

interface CircularProgressBarProps {
    percentage: number
    size?: number
    fontSize?: number
    lineHeight?: number
    completeLabel?: React.ReactNode
    incompleteLabel?: React.ReactNode
    centerLabel?: React.ReactNode
    centerIcon?: React.ReactNode
    centerIconHeight?: number
    segments?: { percentage: number; color: string; label: React.ReactNode }[]
    gaugeColor?: string
    showMilestones?: boolean
}

const CircularProgressBar = ({
    size = DEFAULT_PROGRESS_BAR_SIZE,
    fontSize = DEFAULT_FONT_SIZE,
    lineHeight = DEFAULT_LINE_HEIGHT,
    percentage,
    completeLabel,
    incompleteLabel,
    centerIcon,
    centerLabel,
    segments = [],
    gaugeColor = colors.primary,
    showMilestones = false
}: CircularProgressBarProps) => {
    const maxPercentage = Math.min(percentage, 100)
    const completeBarHeight = (size * maxPercentage) / 100
    const incompleteBarHeight = size - completeBarHeight
    const labelHeight = fontSize * lineHeight

    const labelPosition = useCallback(
        (barHeight: number, labelHeight: number, padding = 0) =>
            barHeight / 2 - labelHeight / 2 + padding,
        []
    )

    const displayLabel = useCallback(
        (barHeight: number, labelHeight: number, padding = 0) =>
            barHeight > labelHeight + padding,
        []
    )

    const milestonesSize = size / 7.5
    return (
        <div className="circular-progressbar-container">
            {showMilestones && (
                <div
                    className="milestones-container"
                    style={{ width: milestonesSize }}
                >
                    <ThunderIcon
                        color={
                            percentage >= 75 ? colors.yellow : colors.lightGrey
                        }
                        size={milestonesSize}
                    />

                    <ThunderIcon
                        color={
                            percentage >= 50 ? colors.yellow : colors.lightGrey
                        }
                        size={milestonesSize}
                    />
                    <ThunderIcon
                        color={
                            percentage >= 25 ? colors.yellow : colors.lightGrey
                        }
                        size={milestonesSize}
                    />
                </div>
            )}
            <div
                className="circular-progressbar--parent"
                aria-label="progress-bar"
                style={{
                    width: size,
                    height: size,
                    fontSize,
                    lineHeight
                }}
            >
                {
                    segments.reduce<{
                        elements: React.ReactNode[]
                        totalHeight: number
                    }>(
                        (computedSegments, segment, index) => {
                            const previousHeight = computedSegments.totalHeight
                            const segmentHeight =
                                (size * segment.percentage) / 100
                            const labelPosition = segmentHeight / 2

                            computedSegments.totalHeight += segmentHeight
                            computedSegments.elements.push(
                                <div
                                    key={index}
                                    style={{
                                        position: 'absolute',
                                        bottom: previousHeight,
                                        width: size
                                    }}
                                >
                                    <div
                                        className="circular-progressbar--segment"
                                        style={{
                                            width: size,
                                            height: `${segmentHeight}px`,
                                            backgroundColor: segment.color,
                                            position: 'absolute',
                                            bottom: 0
                                        }}
                                    />

                                    {segment.label && (
                                        <span
                                            className="circular-progressbar--label"
                                            style={{
                                                position: 'absolute',
                                                left: '50%',
                                                transform:
                                                    'translate(-50%, 50%)',
                                                bottom: `${labelPosition}px`,
                                                color: '#231F20',
                                                fontSize: '12px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {segment.label}
                                        </span>
                                    )}
                                </div>
                            )

                            return computedSegments
                        },
                        { elements: [], totalHeight: 0 }
                    ).elements
                }

                {(centerLabel || centerIcon) && (
                    <span
                        className="circular-progressbar--label"
                        aria-label="progress-center-label"
                        style={{ bottom: 0, top: 0, justifyContent: 'center' }}
                    >
                        {centerIcon}
                        {centerLabel}
                    </span>
                )}
                {incompleteLabel &&
                    displayLabel(incompleteBarHeight, labelHeight, 6) && (
                        <span
                            className="circular-progressbar--label circular-progressbar--incomplete"
                            aria-label="progress-incomplete-label"
                            style={{
                                top: labelPosition(
                                    incompleteBarHeight,
                                    labelHeight,
                                    6
                                )
                            }}
                        >
                            {incompleteLabel}
                        </span>
                    )}
                {completeLabel &&
                    displayLabel(completeBarHeight, labelHeight, 3) && (
                        <span
                            className="circular-progressbar--label circular-progressbar--complete"
                            aria-label="progress-complete-label"
                            style={{
                                bottom: labelPosition(
                                    completeBarHeight,
                                    labelHeight,
                                    3
                                )
                            }}
                        >
                            {completeLabel}
                        </span>
                    )}
                <span
                    aria-label="progress-gauge"
                    style={{
                        width: size,
                        height: `${maxPercentage}%`,
                        backgroundColor: gaugeColor
                    }}
                ></span>
            </div>
        </div>
    )
}

export default CircularProgressBar
