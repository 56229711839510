/* eslint-disable no-unused-vars */
export enum ButtonStylesEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    INFO = 'info',
    WARNING = 'warning',
    LIGHT = 'light',
    DANGER = 'danger',
    SOLID = 'solid',
    OUTLINE = 'outline'
}
