import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { configStore } from './store/configStore'
import './helpers/i18n'
import './main.css'
import reportWebVitals from './reportWebVitals'
import { AppConnectRoutes } from './navigation/AppConnectRoutes'
import { setupSentry } from 'helpers'
import OneSignal from 'react-onesignal'
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'
import { AppModalProvider } from './components'
import { GoogleOAuthProvider } from '@react-oauth/google'
// import { addRxPlugin } from 'rxdb'
// import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const configStores = configStore()

// addRxPlugin(RxDBDevModePlugin)

if (process.env.REACT_APP_ONE_SIGNAL_APP_ID) {
    OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerParam: { scope: '/push/' },
        serviceWorkerPath: '/push/OneSignalSDKWorker.js',
        promptOptions: {
            slidedown: {
                enabled: false
            }
        },
        autoResubscribe: true
    }).then(() => {
        OneSignal.Notifications.requestPermission()
    })
}

if (process.env.REACT_APP_SENTRY_DSN) {
    setupSentry({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENVIRONMENT,
        version: process.env.REACT_APP_APP_VERSION
    })
}

if (process.env.REACT_APP_MATOMO_URL) {
    const instance = createInstance({
        urlBase: process.env.REACT_APP_MATOMO_URL,
        siteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID || '10'),
        linkTracking: false // Important!
    })
    root.render(
        <React.StrictMode>
            <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
            >
                <Provider store={configStores}>
                    <MatomoProvider value={instance}>
                        <AppModalProvider>
                            <AppConnectRoutes />
                        </AppModalProvider>
                    </MatomoProvider>
                </Provider>
            </GoogleOAuthProvider>
        </React.StrictMode>
    )
} else {
    root.render(
        <React.StrictMode>
            <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
            >
                <Provider store={configStores}>
                    <AppModalProvider>
                        <AppConnectRoutes />
                    </AppModalProvider>
                </Provider>
            </GoogleOAuthProvider>
        </React.StrictMode>
    )
}
// register offline pwa
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
