import React, { useEffect, useRef, useState } from 'react'
import {
    CircleMetrics,
    Container,
    Row,
    Col,
    Spinner,
    TimedAlert
} from 'components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/configStore'
import { helpIcon, logoutIcon, locationIcon } from '../../../assets'
import './profile.scss'
import { useBindDispatch, useLogout } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { getConnectUser, constants } from 'helpers'
import { CSSTransition } from 'react-transition-group'
import { FCMetrics } from './fcMetrics'

export const FcProfile = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { loading, error: userDataError } = useSelector(
        ({ home }: RootState) => home
    )

    const fcData = useSelector((state: RootState) => state.fcMetrics.data)
    const { ves, villages } = useSelector((state: RootState) => state.ves)

    const [animationList, setAnimationList] = useState(false)
    const [animate, setAnimate] = useState(false)
    const nodeRef = useRef(null)
    const { logout } = useLogout()
    const { openVillagePageHandler, fcMetricsHandler, fetchVesHandler } =
        useBindDispatch()

    const connectUser = getConnectUser()

    useEffect(() => {
        setAnimate(true)
        if (!fcData && connectUser.staff_code) {
            fcMetricsHandler(connectUser.staff_code)
        }
    }, [fcData, connectUser.staff_code])

    useEffect(() => {
        if (fcData?.fc_info?.zone_code) {
            fetchVesHandler(fcData.fc_info.zone_code)
        }
    }, [fcData])

    const fcMetrics = fcData?.fc_metrics || null

    const fcInfo = fcData?.fc_info || null

    const cardClickHandler = (filterOptions: string[], clientTab: number) => {
        const hasMultipleVillages = connectUser?.veVillages.length > 1

        openVillagePageHandler({
            filterOptions,
            clientTab,
            villageId: hasMultipleVillages
                ? ''
                : connectUser?.veVillages[0].sf_id
        })

        navigate('/village')
    }

    const totalVillages = villages?.length || 0
    const totalVEs = ves?.length || 0
    const totalFarmers = fcMetrics?.total_farmers || 0

    const name = fcInfo?.name || ''

    const zoneName = fcInfo?.zone_name || ''

    const metrics = [
        {
            label: 'Villages',
            value: totalVillages.toString(),
            color: '#A3743B'
        },
        {
            label: 'VEs',
            value: totalVEs.toString(),
            color: '#A3743B'
        },
        {
            label: 'Producteurs',
            value: totalFarmers.toString(),
            color: '#A3743B'
        }
    ]

    const topSection = (
        <>
            <div className="fc-profile-header-container">
                <p className="fc-profile-title">{t('fcProfile.fcTitle')}</p>
                <button
                    className="fc-profile-logout-btn"
                    onClick={() => {
                        logout()
                    }}
                    aria-label="logout"
                >
                    {logoutIcon({ color: '#A3743B' })}
                </button>
            </div>
            <div className="fc-profile-user-info">
                <p className="fc-profile-name">{name}</p>
                <div className="fc-profile-buttons-container">
                    <button
                        className="fc-profile-button"
                        aria-label={t('profile.help')}
                    >
                        {helpIcon({ color: '#A3743B' })}
                        <p>{t('profile.help')}</p>
                    </button>
                </div>
            </div>
            <div className="fc-profile-buttons-container">
                <div className="zone-info">
                    {locationIcon({})}
                    <p> {zoneName}</p>
                </div>
            </div>
            <Col md={12}>{<CircleMetrics metrics={metrics} />}</Col>
        </>
    )

    const FcProfile = loading ? (
        <Spinner size={'90'} pageSpinner={true} fullscreen={true} />
    ) : userDataError ? (
        <TimedAlert type="floating" status="error">
            {userDataError}
        </TimedAlert>
    ) : (
        <CSSTransition
            unmountOnExit
            timeout={constants.ANIMATION_TIMEOUT}
            in={animate}
            classNames="generic"
            appear
            onEnter={() => setAnimationList(true)}
            nodeRef={nodeRef}
        >
            <div className="page-wrapper" ref={nodeRef}>
                <Container className="profile-main">
                    <Row>
                        <Col md={12}>
                            <Col md={12}>{topSection}</Col>
                            <FCMetrics
                                animationList={animationList}
                                fc={fcMetrics}
                                connectUser={connectUser}
                                canViewDeposits={true}
                                canViewDepositGap={true}
                                cardClickHandler={cardClickHandler}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    )
    return FcProfile
}
