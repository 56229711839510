import { ConnectUserType } from './../helpers/appTypes'
import connectApi from '../api/connectApi'
import jwtDecode from 'jwt-decode'
import { constants } from 'helpers'
import i18n from '../helpers/i18n'
import { sendMessageToWorker } from '../helpers/communication'
import {
    AuthActionType,
    AuthEnum,
    AuthMethodEnum,
    AuthMethodType
} from '../storeTypes'
import { Dispatch } from 'redux'
import * as Sentry from '@sentry/react'
import { PersistentStorage } from 'database/PersistentStorage'
import { CollectionNames } from 'database/constants'

const LOGIN_ERROR_MESSAGES = {
    401: 'login.invalidCredentials',
    403: 'login.notAuthorized'
} as { [key: number]: string }

const saveAndGetUser = (token: string) => {
    const user: ConnectUserType = jwtDecode(token)
    user.token = token
    localStorage.setItem(constants.CONNECT_USER, JSON.stringify(user))
    sendMessageToWorker({ 'user info': user })
    return user
}

const authenticateUser = async ({ method, methodData }: AuthMethodType) => {
    const { LOGIN, GOOGLE_LOGIN } = constants.endpoints
    const endpointToUse =
        method === AuthMethodEnum.PASSWORD ? LOGIN : GOOGLE_LOGIN

    const { data, status } = await connectApi.post(endpointToUse, methodData)

    if (status >= 400 || !data?.access_token) {
        const message = LOGIN_ERROR_MESSAGES[status] || 'login.notAuthorized'

        return {
            type: AuthEnum.LOGIN_FAIL,
            payload: {
                message,
                // There is a weird behaviour where the server returns 403 but the status is 0
                // It could be related to a CORS issue
                statusCode: status >= 400 ? status : 403
            }
        }
    }

    const user = saveAndGetUser(data.access_token)

    if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.setUser({
            id: user.user_id.toString(),
            username: user.username
        })
    }
    // set user language
    i18n.changeLanguage(user.language)
    return {
        type: AuthEnum.LOGIN_SUCCESS,
        payload: { message: data.message, statusCode: status, user }
    }
}

export const loginAction =
    (authMethod: AuthMethodType) =>
    async (dispatch: Dispatch<AuthActionType>) => {
        dispatch({ type: AuthEnum.LOGGING })
        try {
            const userAction = await authenticateUser(authMethod)
            dispatch(userAction)
            sendMessageToWorker({ 'sync open tabs': '' })
        } catch (error) {
            dispatch({
                type: AuthEnum.LOGIN_FAIL,
                payload: {
                    message: 'login.invalidCredentials',
                    statusCode: 500
                }
            })
        }
    }

export const logoutAction = () => {
    return async (dispatch: Dispatch<AuthActionType>) => {
        // Erase user info from the service worker
        sendMessageToWorker({ 'user info': {} })

        const rxInstance = await PersistentStorage.get()
        await rxInstance.remove(CollectionNames.NOTIFICATIONS)
        await rxInstance.destroy()

        localStorage.clear()
        dispatch({ type: AuthEnum.LOGOUT })
    }
}
