import React from 'react'
import PropTypes from 'prop-types'
import './layout.scss'
import { LayoutInterface } from './layoutTypes'

export const Row: React.FC<LayoutInterface> = ({
    children,
    className,
    ...props
}) => {
    const classNames = className ? `row ${className}` : 'row'
    return (
        <div className={classNames} {...props}>
            {children}
        </div>
    )
}

Row.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
}
