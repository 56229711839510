import React from 'react'
import {
    clipIcon,
    profileIcon,
    homeIcon,
    NotificationMessageIcon,
    plusIcon,
    MyAgroLogo,
    QRIcon
} from 'assets/svg/svgs'

import { t } from 'i18next'
import { findPermission } from '../../helpers/findPermission'
import { constants } from 'helpers'
import { Button } from 'components'
import { useBaseNavigation } from 'hooks/useBaseNavigation'

export const navBarConfig = ({
    permissionsState,
    hasUnreadNotifications,
    clearClientSearchHandler
}: {
    permissionsState: []
    hasUnreadNotifications: boolean
    clearClientSearchHandler: () => void
}) => {
    const navigate = useBaseNavigation()
    const { VIEW_VILLAGES, CAN_VIEW_LOGO_BUTTON, UPDATE_CLIENT } = constants

    const canViewVillages = findPermission(permissionsState, VIEW_VILLAGES)
    const displayQRButton = findPermission(
        permissionsState,
        constants.CAN_USE_FARMER_ID
    )
    const displayLogoButton = findPermission(
        permissionsState,
        CAN_VIEW_LOGO_BUTTON
    )
    const canEnroll = findPermission(permissionsState, UPDATE_CLIENT)

    const canViewPaymentNotification = findPermission(
        permissionsState,
        constants.CAN_VIEW_PAYMENT_NOTIFICATION
    )

    const qrCodeButton = (
        <div className="qr-code-button--parent">
            <QRIcon />
        </div>
    )

    const clientSearchNavHandler = () => {
        clearClientSearchHandler()
        navigate(displayQRButton ? '/farmer-id' : '/client-search')
    }

    const mobileMenuConfig = [
        {
            name: t('menu.home'),
            path: 'home',
            href: 'home',
            icon: homeIcon,
            isVisible: true,
            onClickCallback: () => navigate('/home')
        },
        {
            name: t('menu.village'),
            path: 'village',
            href: 'village',
            icon: clipIcon,
            isVisible: canViewVillages,
            onClickCallback: () => navigate('/village')
        },
        {
            name: displayQRButton ? (
                qrCodeButton
            ) : displayLogoButton ? (
                <span className={'menu-btn-logo'}>
                    <MyAgroLogo size={24} />
                </span>
            ) : (
                <span className={'menu-plus'}>{plusIcon({})}</span>
            ),
            path: displayQRButton ? 'farmer-id' : 'client-search',
            href: displayQRButton ? 'farmer-id' : 'client-search',
            disableActive: true,
            onClickCallback: clientSearchNavHandler,
            isVisible: canEnroll
        },
        {
            name: t('messages'),
            path: 'notifications',
            href: 'notification/payment-notification',
            icon: (
                <div className={'menu--notification-icon'}>
                    <NotificationMessageIcon />
                    {hasUnreadNotifications && (
                        <div className={'menu--unread-notification-dot'}></div>
                    )}
                </div>
            ),
            isVisible: true,
            onClickCallback: () =>
                navigate('/notification/payment-notification')
        },
        {
            name: t('menu.profile'),
            path: 'profile',
            href: 'profile',
            icon: profileIcon,
            isVisible: true,
            onClickCallback: () => navigate('/profile')
        }
    ]

    const desktopMenuConfig = [
        {
            name: t('menu.home'),
            path: 'home',
            href: 'home',
            icon: homeIcon,
            isVisible: true,
            onClickCallback: () => navigate('/home')
        },
        {
            name: t('menu.village'),
            path: 'village',
            href: 'village',
            icon: clipIcon,
            isVisible: canViewVillages,
            onClickCallback: () => navigate('/village')
        },
        {
            name: t('messages'),
            path: 'notifications',
            href: 'notification/payment-notification',
            icon: (
                <div className={'menu--notification-icon'}>
                    <NotificationMessageIcon />
                    {hasUnreadNotifications && (
                        <div className={'menu--unread-notification-dot'}></div>
                    )}
                </div>
            ),
            isVisible: canViewPaymentNotification,
            onClickCallback: () =>
                navigate('/notification/payment-notification')
        },
        {
            name: t('menu.profile'),
            path: 'profile',
            href: 'profile',
            icon: profileIcon,
            isVisible: true,
            onClickCallback: () => navigate('/profile')
        },
        {
            name: (
                <Button
                    icon={plusIcon({})}
                    iconPos="left"
                    size="small"
                    style="primary"
                >
                    {t('menu.addClient').toString()}
                </Button>
            ),
            path: 'client-search',
            href: 'client-search',
            onClickCallback: clientSearchNavHandler,
            disableActive: true,
            isVisible: canEnroll
        }
    ]

    return { mobileMenuConfig, desktopMenuConfig }
}
