import React, { FC } from 'react'
import { ReferralItemProps } from 'types/clientReferrals'

export const ReferralItem: FC<ReferralItemProps> = ({
    referrerOption,
    isOptionSelected,
    shouldToggleClickButton,
    setSelectedReferrerOption
}) => {
    return (
        <div
            key={referrerOption?.key}
            className={`referrals--${
                isOptionSelected ? 'selected-option' : 'option'
            }`}
            onClick={() =>
                shouldToggleClickButton
                    ? setSelectedReferrerOption(referrerOption)
                    : setSelectedReferrerOption({ option: '' })
            }
            aria-label="referral-option"
        >
            <p>{referrerOption.option}</p>
        </div>
    )
}
