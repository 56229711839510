import { useEffect, useState } from 'react'

export const useAnimation = () => {
    const [animate, setAnimate] = useState<boolean>()

    useEffect(() => {
        setAnimate(true)
    })

    return animate
}
