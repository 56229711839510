import React, { FC } from 'react'
import './numberInput.scss'

type NumberInputProps = {
    length: number
    onChangeNumberHandler: (textValue: string) => void
    className?: string
    initialValue?: string
    gap?: number
    disabled?: boolean
}

export const NumberInput: FC<NumberInputProps> = ({
    length,
    onChangeNumberHandler,
    className = '',
    initialValue = '',
    gap = 0.5,
    disabled = false
}) => {
    document.documentElement.style.setProperty('--gap-ratio', `${gap}`)
    document.documentElement.style.setProperty('--n-char', `${length}`)

    return (
        <input
            className={`number-input-field ${className}`}
            type="number"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length >= length) {
                    e.stopPropagation()
                    e.preventDefault()
                    e.target.value = e.target.value.slice(0, length)
                    return false
                }
            }}
            maxLength={length}
            onChange={(e) =>
                onChangeNumberHandler((e.target as HTMLInputElement).value)
            }
            value={initialValue}
            aria-label="number-input"
            disabled={disabled}
        />
    )
}
