export interface SearchClientListInterface {
    clientCode: string
}

export type CardPayloadType = {
    cardData?: []
    cardDetailData?: []
    searchedClient?: []
    response?: unknown
    loading?: boolean
    error?: string
    cardPaymentResponse?: string
    paymentResponse?: Array<{ status: string; amount?: string }>
    recentCardPayments?: CardPaymentPayloadType[]
}

export enum CardEnum {
    FETCHING_CARD_INVENTORY_DATA = 'fetching card inventory data',
    FETCH_CARD_INVENTORY = 'fetch card inventory',
    FAIL_REQUEST = 'fail request',
    FETCH_CARD_CLIENT_LIST = 'fetch card client list',
    CLEAR_CARD_CLIENT_LIST = 'clear card client list'
}

type FailRequestType = {
    type: CardEnum.FAIL_REQUEST
    payload: string
}

export type StoredDataType = {
    client_code: string
}

type FetchCardClientListType = {
    type: CardEnum.FETCH_CARD_CLIENT_LIST
    payload: {
        allClientData: {}
        storedData: StoredDataType[]
    }
    clientCode: string
}

type ClearCardClientListType = {
    type: CardEnum.CLEAR_CARD_CLIENT_LIST
}

export type CardInventoryResponse = {
    ve_code: string
    date_distributed: string
    cards_in_packet: number
    amount: number
    name: string
    used_cards: number
    active_cards: number
    unusable_cards: number
}

export interface CardInventoryReduxState {
    cardInventory?: CardInventoryResponse[]
    loading?: boolean
    error?: string
}

export interface CardInventoryAction {
    type: CardInventoryEnum
    payload?: CardInventoryResponse[] | string
}

export enum CardInventoryEnum {
    FETCHING_CARD_INVENTORY_DATA = 'fetching card inventory data',
    FETCH_CARD_INVENTORY = 'fetch card inventory',
    FAIL_CARD_INVENTORY_REQUEST = 'fail request'
}

export enum CardPaymentEnum {
    SENDING_CARD_PAYMENT = 'fetching card payment data',
    SENT_CARD_PAYMENT = 'fetch card payment',
    FAILED_CARD_PAYMENT = 'fail request',
    OFFLINE_CARD_PAYMENT = 'offline card payment',
    FETCHING_CARD_PAYMENT_RESULT = 'fetching card payment result',
    FETCHED_CARD_PAYMENT_RESULT = 'fetch card payment result',
    FAILED_CARD_PAYMENT_RESULT = 'fail card payment result',
    CLEAR_CARD_PAYMENT = 'clear card payment',
    CLEAR_CARD_PAYMENT_RESULT = 'clear card payment result'
}

type SendingCardPaymentType = {
    type: CardPaymentEnum.SENDING_CARD_PAYMENT
    payload?: CardPayloadType
}

type SentCardPaymentType = {
    type: CardPaymentEnum.SENT_CARD_PAYMENT
    payload?: string
    payment?: CardPaymentPayloadType
}

type FailedCardPaymentType = {
    type: CardPaymentEnum.FAILED_CARD_PAYMENT
    payload: string
}

type FetchingCardPaymentResultType = {
    type: CardPaymentEnum.FETCHING_CARD_PAYMENT_RESULT
}

type FetchedCardPaymentResultType = {
    type: CardPaymentEnum.FETCHED_CARD_PAYMENT_RESULT
    payload: []
}

type FailedCardPaymentResultType = {
    type: CardPaymentEnum.FAILED_CARD_PAYMENT_RESULT
    payload: string
}

type ClearCardPaymentType = {
    type: CardPaymentEnum.CLEAR_CARD_PAYMENT
}

type ClearCardPaymentResultType = {
    type: CardPaymentEnum.CLEAR_CARD_PAYMENT_RESULT
}

export type CardPaymentActionType =
    | SendingCardPaymentType
    | SentCardPaymentType
    | FailedCardPaymentType
    | FetchingCardPaymentResultType
    | FetchedCardPaymentResultType
    | FailedCardPaymentResultType
    | ClearCardPaymentType
    | ClearCardPaymentResultType

export type CardActionTypes =
    | FailRequestType
    | FetchCardClientListType
    | ClearCardClientListType

export enum CardPaymentStatus {
    SUCCESS = 'success',
    OFFLINE = 'offline',
    FAILED = 'failed',
    PENDING = 'pending'
}

export type CardPaymentPayloadType = {
    transaction_id: string
    date_received: string
    memo: string
    client_code: string
    uuid: string
    amount?: string
    failed_msg?: string
    status?: string
}
export interface CardPaymentInterface {
    paymentData: CardPaymentPayloadType
}

export interface CardPaymentResultInterface {
    [key: string]: string
}
