import { ColoredCardPaymentIcon } from 'assets'
import { amountFormatter } from 'helpers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CardInventoryResponse } from 'storeTypes'

type CardInventoryItemProps = {
    inventory: CardInventoryResponse
}
export const CardInventoryItem = ({ inventory }: CardInventoryItemProps) => {
    const { t } = useTranslation()
    return (
        <div
            key={inventory.name}
            className="list--item"
            aria-label="inventory-item"
        >
            <div className="list--item-left-side">
                <div className="card-inventory--num-cards">
                    <p>{inventory.cards_in_packet}</p>
                </div>
                <p className="list--item--sub-header">{t('cards')}</p>
            </div>
            <div className="list--item--center">
                <p
                    className={'list--item--header card-inventory--amount'}
                    data-testid="amount"
                >
                    {amountFormatter(inventory.amount)}
                </p>
                <p className="list--item--sub-header">
                    {inventory.name.split('-').slice(2).join('-')}
                </p>
            </div>
            <div className="list--item--right-side">
                <ColoredCardPaymentIcon />
            </div>
        </div>
    )
}
