import { HomeActionType, VeHomeEnum, HomeReducerStateType } from '../storeTypes'

const initialState: HomeReducerStateType = {
    userData: [],
    loading: false,
    error: ''
}

export const homeReducer = (
    state: HomeReducerStateType = initialState,
    action: HomeActionType
): HomeReducerStateType => {
    switch (action.type) {
        case VeHomeEnum.FETCH_VE_HOME_DATA:
            return {
                ...state,
                userData: [action.payload],
                loading: false,
                error: ''
            }
        case VeHomeEnum.FETCHING_VE_HOME_DATA:
            return {
                ...state,
                loading: true,
                error: '',
                userData: state.userData || []
            }
        case VeHomeEnum.FAIL_VE_HOME_REQUEST:
            return {
                ...state,
                loading: false,
                userData: state.userData || [],
                error: action.payload || 'Un problème est survenu'
            }
        default:
            return state
    }
}
