import { Button, Container, Modal, ProductSelection, TopBar } from 'components'
import { Steps } from 'components/steps/Steps'
import {
    buildClientUrl,
    capitalizeWord,
    constants,
    enrollmentFlowSteps,
    findPermission,
    formatPayloadToClientObject,
    generateUuidv4,
    getConnectUser,
    isClientInfoComplete,
    ordersAreDifferent
} from 'helpers'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { To, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import {
    useAppSelector,
    useBindDispatch,
    useGeolocation,
    useGetStorage,
    useOnChange
} from 'hooks'
import { WarningModalContent } from 'components/modal/WarningModalContent'
import { useTranslation } from 'react-i18next'
import {
    ClientDetailsStateType,
    PacketSelectionPage,
    ProductInfo,
    SelectedPacket,
    ZoneInfoTemplate
} from 'components/enrollForm'
import { PaymentSelection } from 'components/enrollForm/PaymentSelection'
import { CardPaymentPage } from './CardPaymentPage'
import { TextField } from 'components/enrollForm/TextField'
import { PhoneNumber } from '../clientDetails/PhoneNumber'
import { AgeInput } from '../clientDetails/AgeInput'
import { GenderForm } from '../clientDetails/GenderForm'
import { GroupRoleForm } from '../clientDetails/GroupRoleForm'
import { deleteBlankId, fetchBlankIds } from 'helpers/blankIdsHelper'
import {
    ClientDetailsPayloadInterface,
    ClientStatus,
    EnrollmentInterface
} from 'storeTypes'
import { PopupModal } from 'components/modal/PopupModal'
import { GenderType, GoalItemType } from '../enrollmentTypes'
import { calculateAllEnrollment } from 'helpers/calculateAllEnrollment'
import { SuccessMessage } from 'components/enrollForm/SuccessMessage'
import { InteractionOutcomes } from 'types/interactionOutcomes'
import { ClientReferrals } from 'pages/referrals/ClientReferrals'

const EnrollmentBase = () => {
    const navigate = useNavigate()
    const nodeRef = useRef(null)
    const { villageId } = useParams()
    const [searchParams] = useSearchParams()
    const originUrl = searchParams.get('from')
    const farmerIdBlankId = searchParams.get('farmerIdBlankId')
    const nextURL = searchParams.get('next')
    const { t } = useTranslation()
    const [animate, setAnimate] = useState(false)

    const [activeSteps, setActiveSteps] = useState([1])
    const [totalSteps, setTotalSteps] = useState(
        enrollmentFlowSteps.FULL_ENROLLMENT_FORM_STEPS
    )
    const [selectedProduct, setSelectedProduct] = useState<ProductInfo>()
    const [selectedPackets, setSelectedPackets] = useState<SelectedPacket[]>([])
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
    const [voucherCode, setVoucherCode] = useState('')
    const [voucherAmount, setVoucherAmount] = useState(0)
    const [paymentSource, setPaymentSource] = useState('')
    const [displaySuccessModal, setDisplaySuccessModal] = useState(false)
    const [clientCode, setClientCode] = useState('')
    const [isOnPaymentPage, setIsOnPaymentPage] = useState(false)
    const [selectedReferrerOption, setSelectedReferrerOption] = useState<{
        option: string
        key?: number | undefined
    }>({ option: '' })

    const {
        showWarningModalHandler,
        navbarHandler,
        clientDetailsEnrollmentHandler,
        updatePackagesActionHandler,
        cardPaymentHandler,
        productListHandler,
        sendInteractionsActionHandler
    } = useBindDispatch()

    const { showWarningModal } = useAppSelector((state) => state.showWarning)
    const { productListData, preFetchLoading } = useAppSelector(
        (store) => store.enrollment
    )

    const { country, username, zoneSFID: zone } = getConnectUser()
    const currentClient = useGetStorage(constants.CLIENT_FOR_ENROLLMENT_STORE)
    const { searchQuery, searchResult } = useAppSelector(
        (state) => state.clientSearch
    )
    const missingClientInfo = useMemo(() => {
        if (
            currentClient?.form_type === 're-enrollment' ||
            currentClient?.form_type === 'modification'
        ) {
            return !isClientInfoComplete(currentClient)
        }
        return false
    }, [])

    const { inputText, onChangeHandler } = useOnChange<ClientDetailsStateType>({
        firstname:
            currentClient?.firstname ||
            (searchResult && searchResult.length > 0
                ? ''
                : searchQuery?.firstName),
        lastname:
            currentClient?.lastname ||
            (searchResult && searchResult.length > 0
                ? ''
                : searchQuery?.lastName)
    } as ClientDetailsStateType)

    const [phoneNumberInput, setPhoneNumberInput] = useState(
        currentClient?.phone ||
            (searchResult && searchResult.length > 0
                ? ''
                : searchQuery?.primaryPhone)
    )
    const [selectedAge, setSelectedAge] = useState<number | null | undefined>(
        currentClient?.age || null
    )
    const [selectedGender, setSelectedGender] = useState<GenderType>(
        currentClient?.sex?.toLowerCase() || null
    )
    const [selectedGroupRole, setSelectedGroupRole] = useState<
        Record<string, boolean>
    >({
        familyLeader: currentClient?.head_of_household,
        groupLeader: currentClient?.group_leader
    })

    const { geolocation } = useGeolocation({ permissionRequired: false })

    const zoneProductsInfo = useMemo(
        () =>
            productListData?.find(
                (product: ZoneInfoTemplate) => product.zone_sf_id === zone
            ) as unknown as ZoneInfoTemplate,
        [productListData]
    )

    const isClientRegistration =
        !currentClient &&
        totalSteps === enrollmentFlowSteps.CLIENT_DETAILS_FORM_STEPS

    const isClientModification =
        currentClient?.modification ===
            constants.CLIENT_MODIFICATION_TYPES.DETAILS &&
        currentClient?.form_type === 'modification'

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const canViewReferralFlow = findPermission(
        userPermissions,
        constants.CAN_VIEW_REFERRALS_ENROLLMENT_FLOW
    )

    const changeActiveSteps = () =>
        setActiveSteps((prevSteps) => [
            ...prevSteps,
            prevSteps[prevSteps.length - 1] + 1
        ])

    const clientSubmissionHandler = (blankId: string) => {
        const clientCode = currentClient?.client_code || blankId
        const uuid = generateUuidv4()
        const clientDetailsPayload = {
            ...inputText,
            fullname: `${inputText.firstname} ${inputText.lastname}`,
            phone: phoneNumberInput,
            client_code: clientCode,
            username,
            date: new Date().toISOString(),
            form_type: currentClient?.form_type || 'enrollment',
            village: villageId,
            village_id: villageId,
            villageName: currentClient?.villageName || '',
            client_status:
                currentClient?.client_status || ClientStatus.POTENTIAL,
            uuid,
            farmerIdBlankId,
            sex: selectedGender && capitalizeWord(selectedGender),
            age: selectedAge,
            group_leader: selectedGroupRole.groupLeader,
            head_of_household: selectedGroupRole.familyLeader
        } as Partial<ClientDetailsPayloadInterface>

        if (selectedReferrerOption || inputText?.referrer) {
            clientDetailsPayload.referrer =
                selectedReferrerOption?.option || inputText.referrer
        }
        if (selectedPackets?.length === 0 && !currentClient) {
            // when we enroll the store client_for_enrollment is undefined. that is why we check !currenClient
            sendInteractionsActionHandler({
                client_code: clientCode,
                client_status: ClientStatus.POTENTIAL,
                user_id: username,
                survey_name: constants.CLIENT_REGISTRATION_SURVEY_NAME,
                survey_version: constants.CLIENT_REGISTRATION_SURVEY_VERSION,
                village_id: villageId || ''
            })

            sendInteractionsActionHandler({
                client_code: clientCode,
                client_status: ClientStatus.POTENTIAL,
                user_id: username,
                survey_name: constants.TARGET_LIST_SURVEY_NAME,
                survey_version: constants.TARGET_LIST_SURVEY_VERSION,
                village_id: villageId || '',
                outcome: InteractionOutcomes.FOLLOW_UP
            })
        }

        setClientCode(clientCode)
        if (
            currentClient?.modification !==
            constants.CLIENT_MODIFICATION_TYPES.PACKAGES
        ) {
            clientDetailsEnrollmentHandler(
                { clientDetailsPayload },
                geolocation
            )
        }

        const formattedClientPayload =
            currentClient?.modification ===
            constants.CLIENT_MODIFICATION_TYPES.DETAILS
                ? { ...currentClient, ...clientDetailsPayload }
                : formatPayloadToClientObject(clientDetailsPayload)

        localStorage.setItem(
            constants.CLIENT_DETAIL_LOCAL_STORE,
            JSON.stringify(formattedClientPayload)
        )
        setDisplaySuccessModal(true)
        !currentClient && deleteBlankId(blankId)
    }

    const ordersSubmissionHandler = (blankId?: string) => {
        const clientCode = currentClient?.client_code || blankId
        const isPackageModification =
            currentClient?.modification ===
            constants.CLIENT_MODIFICATION_TYPES.PACKAGES
        const ordersPayload = {
            ...inputText,
            sex: selectedGender && capitalizeWord(selectedGender),
            age: selectedAge,
            group_leader: selectedGroupRole.groupLeader,
            head_of_household: selectedGroupRole.familyLeader,
            phone: phoneNumberInput,
            client_code: clientCode,
            packets: selectedPackets,
            goal_items: isPackageModification ? currentClient?.goal_items : [],
            form_type: currentClient?.form_type || 'enrollment',
            village: currentClient?.village_id || villageId,
            client_status: ClientStatus.PACKAGE_WITHOUT_PAYMENT,
            balance: currentClient?.balance || 0
        }

        const formattedOrders = formatPayloadToClientObject(ordersPayload)

        localStorage.setItem(
            constants.CLIENT_DETAIL_LOCAL_STORE,
            JSON.stringify(formattedOrders)
        )

        if (ordersAreDifferent(currentClient?.goal_items, selectedPackets)) {
            const isPackageEnrollment =
                !currentClient?.form_type ||
                currentClient?.form_type === 'enrollment'

            sendInteractionsActionHandler({
                survey_data: { orders: selectedPackets },
                client_code: currentClient?.client_code || clientCode,
                client_status:
                    currentClient?.client_status || ClientStatus.POTENTIAL,
                user_id: username,
                survey_name: isPackageEnrollment
                    ? constants.PACKAGE_ENROLLMENT_SURVEY_NAME
                    : constants.PACKAGE_MODIFICATION_SURVEY_NAME,
                survey_version: isPackageEnrollment
                    ? constants.PACKAGE_ENROLLMENT_SURVEY_VERSION
                    : constants.PACKAGE_MODIFICATION_SURVEY_VERSION,
                village_id: currentClient?.village_id || villageId
            })

            if (isPackageEnrollment) {
                sendInteractionsActionHandler({
                    client_code: currentClient?.client_code || clientCode,
                    client_status:
                        currentClient?.client_status || ClientStatus.POTENTIAL,
                    user_id: username,
                    survey_name: constants.TARGET_LIST_SURVEY_NAME,
                    survey_version: constants.TARGET_LIST_SURVEY_VERSION,
                    village_id: currentClient?.village_id || villageId,
                    outcome: InteractionOutcomes.ENROLLED
                })
            }

            updatePackagesActionHandler(
                formattedOrders,
                currentClient?.goal_items || [],
                selectedPackets || []
            )
        }
    }

    const paymentSubmissionHandler = (clientCode: string, voucher?: string) => {
        const paymentUuid = generateUuidv4()
        const paymentData = {
            transaction_id: voucherCode || (voucher as string),
            date_received: new Date().toISOString(),
            memo: username,
            source: paymentSource,
            client_code: clientCode,
            uuid: paymentUuid,
            village: villageId
        }
        cardPaymentHandler({
            paymentData
        })
    }

    const handleSubmit = async (voucher?: string) => {
        const blankIdRes =
            farmerIdBlankId ||
            currentClient?.client_code ||
            (await fetchBlankIds()).blank_id.code.toString()

        clientSubmissionHandler(blankIdRes)
        if (selectedPackets.length >= 1) {
            // call order submit handler
            ordersSubmissionHandler(blankIdRes)
        }
        if (voucher) {
            paymentSubmissionHandler(blankIdRes, voucher)
        }
    }

    const productSelectionComponent = () => {
        return (
            <>
                <p className="enrollment--step-title">
                    {t('enrollment.selectPackages')}
                </p>
                <ProductSelection
                    selectedPackets={selectedPackets}
                    onProductSelected={(product: ProductInfo) => {
                        setSelectedProduct(product)
                        changeActiveSteps()
                    }}
                    zoneProductsInfo={zoneProductsInfo}
                    onClickHandler={() => {
                        if (currentClient?.form_type === 'modification') {
                            handleSubmit()
                            changeActiveSteps()
                        } else {
                            changeActiveSteps()
                            changeActiveSteps()
                        }
                    }}
                    btnText={
                        currentClient?.form_type === 'modification'
                            ? t('subscribe')
                            : t('pay')
                    }
                />
            </>
        )
    }

    const packetSelectionComponent = ({
        continueButtonText
    }: { continueButtonText?: string } = {}) => {
        return (
            <div className="enrollment-step-container">
                <PacketSelectionPage
                    selectedPackets={selectedPackets}
                    selectedProduct={selectedProduct as ProductInfo}
                    onPacketSelection={setSelectedPackets}
                    onOtherPacketsClick={() => {
                        setSelectedProduct(undefined)
                        backActiveSteps()
                    }}
                    handlePacketConfirmation={() => {
                        if (
                            currentClient?.modification ===
                            constants.CLIENT_MODIFICATION_TYPES.PACKAGES
                        ) {
                            ordersSubmissionHandler()
                            setDisplaySuccessModal(true)
                        } else {
                            changeActiveSteps()
                        }
                    }}
                    continueButtonText={continueButtonText}
                />
            </div>
        )
    }

    const paymentMethodComponent = ({
        mobilePaymentContinueButtonText
    }: {
        mobilePaymentContinueButtonText?: string
    } = {}) => {
        return (
            <PaymentSelection
                totalEnrollment={calculateAllEnrollment(selectedPackets)}
                onPaymentMethodSelected={async (paymentMethod: string) => {
                    setSelectedPaymentMethod(paymentMethod)

                    currentClient?.form_type === 're-enrollment' &&
                    !missingClientInfo &&
                    paymentMethod !== constants.PAYMENT_OPTIONS.SCRATCH_CARD
                        ? await handleSubmit()
                        : changeActiveSteps()
                }}
                isOnPaymentPage={setIsOnPaymentPage}
                mobilePaymentContinueButtonText={
                    mobilePaymentContinueButtonText
                }
                cardPaymentContinueButtonText={t('continue')}
            />
        )
    }

    const cardPaymentComponent = ({
        continueButtonText
    }: {
        continueButtonText?: string
    } = {}) => {
        return (
            <CardPaymentPage
                voucherCode={voucherCode}
                voucherAmount={voucherAmount}
                paymentSource={paymentSource}
                continueButtonText={continueButtonText}
                onPaymentConfirmation={async (
                    voucherCode,
                    voucherAmount,
                    source
                ) => {
                    setVoucherCode(voucherCode)
                    setVoucherAmount(voucherAmount)
                    setPaymentSource(source)
                    totalSteps.STEPS > activeSteps.length
                        ? changeActiveSteps()
                        : await handleSubmit(voucherCode)
                }}
            />
        )
    }

    const ageFormComponent = () => (
        <AgeInput
            attribute="age"
            nextStepHandler={() => changeActiveSteps()}
            selectedAge={selectedAge}
            setSelectedAge={setSelectedAge}
        />
    )
    const genderFormComponent = () => (
        <GenderForm
            attribute="gender"
            nextStepHandler={() => changeActiveSteps()}
            selectedGender={selectedGender}
            setSelectedGender={setSelectedGender}
            selectedAge={selectedAge as number}
        />
    )
    const groupRoleFormComponent = () => (
        <GroupRoleForm
            attribute="groupRole"
            nextStepHandler={() => changeActiveSteps()}
            selectedGroupRole={selectedGroupRole}
            setSelectedGroupRole={setSelectedGroupRole}
        />
    )

    const firstNameComponent = () => (
        <TextField
            key="firstname"
            attribute="firstname"
            inputValue={inputText}
            onChangeHandler={onChangeHandler}
            onClickHandler={() => changeActiveSteps()}
        />
    )

    const lastNameComponent = () => (
        <TextField
            key="lastname"
            attribute="lastname"
            inputValue={inputText}
            onChangeHandler={onChangeHandler}
            onClickHandler={() => changeActiveSteps()}
        />
    )

    const phoneNumberComponent = () => (
        <PhoneNumber
            phoneNumberHandler={setPhoneNumberInput}
            phoneNumberInputValue={phoneNumberInput}
            continueButtonText={
                isClientModification ? t('modify') : t('finish')
            }
            userCountry={country}
            handleSubmit={() => handleSubmit(voucherCode)}
        />
    )

    const clientDetailsFlow = () => [
        firstNameComponent(),
        lastNameComponent(),
        phoneNumberComponent()
    ]

    const moreClientDetailsFormComponents = () => [
        ageFormComponent(),
        genderFormComponent(),
        groupRoleFormComponent(),
        ...clientDetailsFlow()
    ]
    const packageFlow = () => [
        productSelectionComponent(),
        packetSelectionComponent({
            continueButtonText: t('finish')
        })
    ]

    const reEnrollmentFlow = () => [
        productSelectionComponent(),
        packetSelectionComponent(),
        paymentMethodComponent({
            mobilePaymentContinueButtonText: t('finish')
        }),
        cardPaymentComponent({
            continueButtonText: t('finish')
        })
    ]

    const referralsComponent = () => (
        <ClientReferrals
            selectedReferrerOption={selectedReferrerOption}
            setSelectedReferrerOption={setSelectedReferrerOption}
            inputText={inputText}
            onChangeHandler={onChangeHandler}
            nextStepHandler={() => changeActiveSteps()}
        />
    )

    const processComponentsToBeUsed = (
        components: JSX.Element[],
        componentToRemoveIndex: number
    ) =>
        canViewReferralFlow && !currentClient
            ? components
            : components.filter((_, index) => index !== componentToRemoveIndex)

    // Use this function where you want to change the flow
    // i.e when the user skips the package selection or skips the card payment

    const buildLongestFlow = () => {
        const longFlowComponents = [
            productSelectionComponent(),
            packetSelectionComponent(),
            paymentMethodComponent(),
            cardPaymentComponent(),
            referralsComponent(),
            ...clientDetailsFlow()
        ]
        return processComponentsToBeUsed(longFlowComponents, 4)
    }

    const buildMediumFlow = () => {
        const mediumFlowComponents = [
            productSelectionComponent(),
            packetSelectionComponent(),
            paymentMethodComponent(),
            referralsComponent(),
            ...clientDetailsFlow()
        ]
        return processComponentsToBeUsed(mediumFlowComponents, 3)
    }

    const buildShortFlow = () => {
        return [productSelectionComponent(), ...clientDetailsFlow()]
    }

    const [currentFlow, setCurrentFlow] = useState(buildMediumFlow())

    useEffect(() => {
        navbarHandler(constants.HIDE_PART_NAVBAR)

        return () => {
            navbarHandler(constants.SHOW_NAVBAR)
        }
    }, [])

    useEffect(() => {
        if (currentClient?.goal_items?.length && productListData?.length) {
            const preSelectedPackets = currentClient.goal_items.reduce(
                (selectedPackets: SelectedPacket[], order: GoalItemType) => {
                    const product = zoneProductsInfo.product_list.find(
                        (product: ProductInfo) =>
                            product.sf_id === order.product.id
                    )

                    selectedPackets.push({
                        product_id: order.product.id,
                        pricebook_id:
                            product?.product_prices[0]?.pricebook?.sf_id || '',
                        size: order.desired_units,
                        size_display: order.desired_units_display,
                        name: order.product.name,
                        hidePrice: product?.hide_price || false,
                        price: order.price?.toString(),
                        unit: product?.unit || '',
                        uuid: order.uuid
                    })
                    return selectedPackets
                },
                []
            )
            setSelectedPackets(selectedPackets.concat(preSelectedPackets))
        }
    }, [productListData])

    useEffect(() => {
        if (currentClient?.form_type === 're-enrollment') {
            if (
                selectedPaymentMethod === constants.PAYMENT_OPTIONS.SCRATCH_CARD
            ) {
                setTotalSteps(
                    missingClientInfo
                        ? enrollmentFlowSteps.FULL_ENROLLMENT_FORM_WITH_VOUCHER_STEPS
                        : enrollmentFlowSteps.CLIENT_DETAILS_FORM_STEPS
                )
            } else {
                setTotalSteps(
                    missingClientInfo
                        ? enrollmentFlowSteps.FULL_ENROLLMENT_FORM_STEPS
                        : enrollmentFlowSteps.CLIENT_REENROLLMENT_FORM_STEPS
                )
            }
        } else if (
            currentClient?.modification ===
            constants.CLIENT_MODIFICATION_TYPES.DETAILS
        ) {
            setTotalSteps(enrollmentFlowSteps.CLIENT_MODIFICATION_FORM_STEPS)
        } else if (
            currentClient?.modification ===
            constants.CLIENT_MODIFICATION_TYPES.PACKAGES
        ) {
            setTotalSteps(enrollmentFlowSteps.PACKAGE_MODIFICATION_FORM_STEPS)
        } else {
            setTotalSteps(
                selectedPaymentMethod === constants.PAYMENT_OPTIONS.SCRATCH_CARD
                    ? {
                          STEPS: buildLongestFlow().length,
                          LABEL: enrollmentFlowSteps
                              .FULL_ENROLLMENT_FORM_WITH_VOUCHER_STEPS.LABEL
                      }
                    : {
                          STEPS: buildMediumFlow().length,
                          LABEL: enrollmentFlowSteps.FULL_ENROLLMENT_FORM_STEPS
                              .LABEL
                      }
            )
        }
    }, [isOnPaymentPage, voucherCode])

    useEffect(() => {
        setAnimate(true)
        switch (totalSteps.LABEL) {
            case enrollmentFlowSteps.FULL_ENROLLMENT_FORM_WITH_VOUCHER_STEPS
                .LABEL:
                setCurrentFlow(buildLongestFlow())
                break
            case enrollmentFlowSteps.CLIENT_DETAILS_FORM_STEPS.LABEL:
                setCurrentFlow(
                    currentClient?.form_type === 're-enrollment'
                        ? reEnrollmentFlow()
                        : buildShortFlow()
                )
                break
            case enrollmentFlowSteps.CLIENT_MODIFICATION_FORM_STEPS.LABEL:
                setCurrentFlow(moreClientDetailsFormComponents())
                break
            case enrollmentFlowSteps.CLIENT_REENROLLMENT_FORM_STEPS.LABEL:
                setCurrentFlow(reEnrollmentFlow())
                break
            case enrollmentFlowSteps.PACKAGE_MODIFICATION_FORM_STEPS.LABEL:
                setCurrentFlow(packageFlow())
                break
            default:
                setCurrentFlow(buildMediumFlow())
        }
    }, [
        totalSteps,
        activeSteps,
        selectedPackets,
        inputText,
        phoneNumberInput,
        clientCode,
        productListData,
        showWarningModal,
        isOnPaymentPage,
        selectedPaymentMethod,
        voucherCode,
        selectedAge,
        selectedGender,
        selectedGroupRole,
        country,
        selectedReferrerOption
    ])

    useEffect(() => {
        if (productListData?.length === 0 && !preFetchLoading) {
            productListHandler({ zoneSFID: zone } as EnrollmentInterface)
        }
    }, [])

    const backActiveSteps = () =>
        setActiveSteps((prevActiveSteps) =>
            isOnPaymentPage
                ? prevActiveSteps.slice(0, -2)
                : prevActiveSteps.slice(0, -1)
        )

    const backButtonHandler = () => {
        const isOnEnrollWithoutPackagesStep =
            totalSteps === enrollmentFlowSteps.CLIENT_DETAILS_FORM_STEPS &&
            activeSteps.length === 2

        const isOnCardPaymentStep =
            totalSteps ===
                enrollmentFlowSteps.FULL_ENROLLMENT_FORM_WITH_VOUCHER_STEPS &&
            activeSteps.length === 4

        if (activeSteps.length === 1) return exitHandler()

        if (isOnEnrollWithoutPackagesStep || isOnCardPaymentStep) {
            setTotalSteps(enrollmentFlowSteps.FULL_ENROLLMENT_FORM_STEPS)
            return backActiveSteps()
        }

        setSelectedProduct(undefined)
        backActiveSteps()
    }

    const showWarningModalCallback = useCallback(
        (status: boolean) => {
            showWarningModalHandler(status)
        },
        [showWarningModalHandler]
    )

    const exitHandler = () => {
        if (!showWarningModal) {
            showWarningModalCallback(true)
            return
        }
        showWarningModalCallback(false)
        if (currentClient?.form_type === 'modification' && !originUrl) {
            return navigate(-1 as To, { replace: true })
        }
        navigate(`${originUrl || '/client-search'}`, { replace: true })
    }

    const warningModal = () => {
        return (
            <Modal
                okClickHandler={exitHandler}
                cancelClickHandler={() => showWarningModalCallback(false)}
                showModal={showWarningModal || false}
                okButtonText={t('yes')}
                cancelButtonText={t('no')}
                data-testid="mark-delivery-modal"
                position={'center'}
                textHeader={t('visitWarningHeader')}
            >
                <WarningModalContent warningText={t('visitWarningBody')} />
            </Modal>
        )
    }

    const showWarningModalMemo = useMemo(() => {
        if (showWarningModal) {
            return warningModal()
        }
    }, [showWarningModal])

    const navigateToNextURL = () => {
        if (nextURL) {
            if (nextURL === '/visit') {
                localStorage.setItem(
                    constants.CLIENT_FOR_VISIT_STORE,
                    JSON.stringify({
                        clientCode: currentClient.client_code,
                        villageId: currentClient.village_id || villageId,
                        clientName: currentClient.firstname,
                        origin: constants.ORIGIN_CLIENT_DETAIL
                    })
                )
            }
            return navigate(nextURL, { replace: true })
        }
        const allEnrollment =
            selectedPackets.length > 0
                ? calculateAllEnrollment(selectedPackets)
                : currentClient?.all_enrollment
        navigate(
            buildClientUrl({
                all_enrollment: allEnrollment,
                client_code: clientCode || currentClient?.client_code,
                village_id: villageId
            }),
            { replace: true }
        )
    }

    const enrollmentBaseClasses = [
        'enrollment-base--container',
        isClientRegistration ? 'enrollment-base--flow-client-registration' : '',
        isClientModification ? 'enrollment-base--flow-client-modification' : ''
    ].join(' ')

    return (
        <>
            <CSSTransition
                unmountOnExit
                timeout={constants.ANIMATION_TIMEOUT}
                in={animate}
                classNames="generic"
                appear
                nodeRef={nodeRef}
            >
                <div ref={nodeRef}>
                    <Container>
                        <TopBar
                            back
                            backNavigation={backButtonHandler}
                            close={exitHandler}
                            iconColor="#000000"
                            transparentTopBar
                        >
                            {isClientRegistration
                                ? t('enrollment.registration')
                                : isClientModification
                                ? t('modify')
                                : t('enrollment.enrollment')}
                        </TopBar>
                    </Container>
                    <div className={enrollmentBaseClasses}>
                        <Steps
                            steps={totalSteps.STEPS}
                            activeSteps={activeSteps}
                        />
                        {activeSteps.length <= 1 &&
                            !currentClient?.modification &&
                            currentClient?.form_type !== 're-enrollment' &&
                            selectedPackets?.length <= 0 && (
                                <div className="enrollment-base--no-packages-btn">
                                    <Button
                                        style="primary"
                                        size="small"
                                        onClick={() => {
                                            setSelectedPackets([])
                                            setTotalSteps(
                                                enrollmentFlowSteps.CLIENT_DETAILS_FORM_STEPS
                                            )
                                            changeActiveSteps()
                                        }}
                                    >
                                        {t('enrollment.enrollNoPackages')}
                                    </Button>
                                </div>
                            )}
                        {/* This is where we render the active step */}
                        <div className="enrollment-base--step-content">
                            {currentFlow[activeSteps.length - 1]}
                        </div>
                        {displaySuccessModal && (
                            <PopupModal
                                closeModal={() => {
                                    setDisplaySuccessModal(false)
                                    navigateToNextURL()
                                }}
                            >
                                <SuccessMessage
                                    firstName={inputText.firstname}
                                    clientCategoryForIconColor={
                                        (isClientRegistration &&
                                            'clientRegistration') ||
                                        (isClientModification &&
                                            'clientModification') ||
                                        'default'
                                    }
                                />
                            </PopupModal>
                        )}
                    </div>
                </div>
            </CSSTransition>
            {showWarningModalMemo}
        </>
    )
}

export default EnrollmentBase
