import { Container, TopBar } from 'components'
import { DownloadVeCard } from 'components/card/DownloadVeCard'
import { constants } from 'helpers'
import { useAppSelector, useGetStorage } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RootState } from 'store/configStore'
import { VeType } from 'storeTypes/vesTypes'
import './style/fcSearch.scss'
import { NotAvailableIcon } from 'assets'
import { useNavigate } from 'react-router-dom'

export const FcSearchResults = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [searchResults, setSearchResults] = useState<VeType[]>()

    const locallyStoredResults = useGetStorage(constants.fcSearchResults)

    const fcData = useAppSelector((state: RootState) => state.fcMetrics?.data)

    const veSearch = useAppSelector((state: RootState) => state.veSearch)

    useEffect(() => {
        if (veSearch.results.length > 0 && !locallyStoredResults) {
            localStorage.setItem(
                constants.fcSearchResults,
                JSON.stringify(veSearch.results)
            )
            setSearchResults(veSearch.results)
        } else {
            setSearchResults(locallyStoredResults)
        }
    }, [veSearch.results])

    const searchClickHandler = (ve: VeType) =>
        navigate(`/fc/village/ve/${ve.code}/profile`)

    return (
        <div>
            <Container>
                <TopBar iconColor="#000000" transparentTopBar back>
                    {t('zone')} {fcData?.fc_info?.zone_name}
                </TopBar>
                <p className="card-search--title">{t('clientSearchResult')}</p>
            </Container>
            <div className="search-results--content">
                {searchResults && searchResults.length > 0 ? (
                    searchResults.map((result) => (
                        <DownloadVeCard
                            key={result.code}
                            ve={result}
                            openClickHandler={searchClickHandler}
                        />
                    ))
                ) : (
                    <div className="tabs--empty">
                        <NotAvailableIcon />
                        <div className="tabs--empty-text">
                            <p>{t('village.veNoResults')}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
