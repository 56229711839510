import { constants } from 'helpers'
import { useBindDispatch } from 'hooks'
import OneSignal from 'react-onesignal'

export const useLogout = () => {
    const { logoutDispatch } = useBindDispatch()
    const logout = () => {
        localStorage.removeItem(constants.CONNECT_USER)
        localStorage.removeItem(constants.CLIENT_LIST_EXPIRATION_NAME)
        localStorage.removeItem(constants.ENROLLMENT_FORM_CACHED_NAME)
        logoutDispatch()
        window.location.href = '/login'
        // reset active menu
        sessionStorage.removeItem('activeMenu')

        // remove onesignal subscription
        OneSignal.logout()
    }
    return { logout }
}
