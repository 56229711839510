import {
    ClientDetailsPayloadInterface,
    TransactionPayloadType
} from 'storeTypes'
import { groupBy } from 'lodash'
import { constants, sortHandler } from 'helpers'
import { calculateBalance } from './processClientTransactionHelpers'

export const getClientTransactionDetails = (
    { client_code: clientCode, balance }: ClientDetailsPayloadInterface,
    transactions: TransactionPayloadType[]
) => {
    const transactionsByClient = groupBy(transactions, 'client_code')
    const clientTransactions = sortHandler(
        transactionsByClient[clientCode] || [],
        'date_received',
        constants.SORT_TYPE.DATE,
        constants.SORT_DIRECTION.DESCENDING
    )
    const calculatedBalance = calculateBalance(clientTransactions)
    const clientBalance =
        balance >= (calculatedBalance || 0) ? balance : calculatedBalance
    const lastPaymentDate = clientTransactions[0]?.date_received || ''

    return {
        clientBalance,
        transactionList: clientTransactions,
        lastPaymentDate
    }
}
