import { EmptyList } from 'components/emptyList/EmptyList'
import { Col, Row } from 'components/layout'
import React from 'react'
import { TargetFilter } from './TargetFilter'
import { Target } from './Target'
import { TargetListSearch } from './TargetListSearch'
import { TargetPayloadType } from 'storeTypes'

type TargetListType = {
    targetScrollRef: React.RefObject<HTMLDivElement>
    searchValue: string
    onSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void
    sortedTargetList: TargetPayloadType[]
    targetListClickHandler?: (
        villageId: string,
        clientCode: string,
        clientStatus: string
    ) => void
    storeSelectedFilter: string
    filterTargetListHandler: (selectedFilter: string, veCode: string) => void
    veCode: string
    t: (key: string) => string
    searchIcon?: JSX.Element
    searchIconPos?: 'left' | 'right'
    searchPlaceholder?: string
}

export const TargetList = ({
    targetScrollRef,
    searchValue,
    onSearchInput,
    sortedTargetList,
    storeSelectedFilter,
    filterTargetListHandler,
    veCode,
    t,
    searchIcon,
    searchIconPos,
    searchPlaceholder
}: TargetListType) => {
    return (
        <div
            ref={targetScrollRef}
            className="target-list"
            data-testid="target-list"
        >
            <Row>
                <Col md={12}>
                    <h2 className="target-list--header target-list--parent">
                        {t('targetList.targetListHeaderTitle')}
                    </h2>
                    <TargetListSearch
                        onSearchInput={onSearchInput}
                        searchValue={searchValue}
                        t={t}
                        searchIcon={searchIcon}
                        searchIconPos={searchIconPos}
                        searchPlaceholder={searchPlaceholder}
                    />
                    <div>
                        <TargetFilter
                            filterHandler={filterTargetListHandler}
                            veCode={veCode}
                        />
                    </div>
                </Col>
                <div className="card-session target-list--card-session">
                    <div>
                        {sortedTargetList.length > 0 ? (
                            sortedTargetList.map((singleTarget) => (
                                <Target
                                    targetData={singleTarget}
                                    key={singleTarget.client_code}
                                    targetOnClick={() => {}}
                                    filter={storeSelectedFilter || ''}
                                />
                            ))
                        ) : (
                            <EmptyList message={t('notAvailable.targetList')} />
                        )}
                    </div>
                </div>
            </Row>
        </div>
    )
}
