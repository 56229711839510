import React from 'react'
import './select.scss'
import { SelectInterface } from './selectTypes'

export const Select: React.FC<SelectInterface> = ({
    options,
    errorValidation,
    ...props
}) => {
    const inputError = errorValidation ? 'generic--error-border' : ''
    return (
        <select className={`select-input ${inputError}`} {...props}>
            {options?.length > 0 &&
                options.map((option, idx) => {
                    return (
                        <option
                            key={idx}
                            value={option.value}
                            id={`select-option-${option?.label?.replaceAll(
                                ' ',
                                '-'
                            )}`}
                        >
                            {option.label}
                        </option>
                    )
                })}
        </select>
    )
}
