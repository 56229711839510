import React from 'react'
import { CashHandIcon, RightArrowIcon, MyAgroLogo } from 'assets'
import './depositComponent.scss'

export const RemainDepositBadge = ({
    totalRemainingDeposit
}: {
    totalRemainingDeposit: string
}) => {
    return (
        <div className="deposits--total-container" aria-label="deposit-section">
            <div className="deposits--total">
                <div className="deposits--total-icons">
                    <CashHandIcon width="35" height="35" />
                    <RightArrowIcon width="25" height="25" />
                    <MyAgroLogo size={'30'} />
                </div>
                <p className="deposits--total-text">{totalRemainingDeposit}</p>
            </div>
        </div>
    )
}
