import React, { FC, ReactNode } from 'react'
import { FootIcon, NotificationIcon } from 'assets'
import { useTranslation } from 'react-i18next'
import { NotificationType } from 'storeTypes'
import { constants } from 'helpers'

type SingleNotificationType = {
    children: ReactNode
    notificationData: NotificationType
    onClick?: () => void
}

export const SingleNotification: FC<SingleNotificationType> = ({
    children,
    notificationData,
    onClick
}) => {
    const { t } = useTranslation()
    const {
        delivery_date: deliveryDate,
        read_status: isRead = false,
        type,
        sub_type: subType
    } = notificationData

    return (
        <div
            className="single-notification--container"
            onClick={onClick}
            aria-label="single-notification"
        >
            <div>
                <p className="single-notification--date">
                    {t('fullDateMonthYear', {
                        date: new Date(deliveryDate)
                    })}
                </p>
            </div>
            <div
                className={`single-notification--content ${
                    !isRead ? 'single-notification--content-unread' : ''
                }`}
            >
                <div className="single-notification--icon">
                    {type.toUpperCase() ===
                        constants.NOTIFICATIONS.TYPES.REMINDER &&
                    subType.toUpperCase() ===
                        constants.NOTIFICATIONS.SUB_TYPES.PAYMENT ? (
                        <FootIcon width="40" height="40" />
                    ) : (
                        <NotificationIcon />
                    )}
                </div>
                <div className="single-notification--text">
                    <p
                        className="single-notification--text-content"
                        aria-label="notification-text-content"
                    >
                        {children}
                    </p>
                </div>
                {!isRead && (
                    <div
                        className="single-notification--dot"
                        aria-label="unread-dot"
                    />
                )}
            </div>
        </div>
    )
}
