import React, { useCallback, useEffect, useMemo } from 'react'
import { generateUuidv4, getConnectUser } from '../../helpers'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Container, Modal, TopBar } from 'components'
import { VisitForm, VisitFormState } from '../../components/visitForm/VisitForm'
import { useTranslation } from 'react-i18next'
import './visit.scss'
import '../enrollment/enrollment.scss'
import {
    useBindDispatch,
    useGetStorage,
    useAppSelector,
    useBackAction
} from '../../hooks'
import { useGeolocation } from '../../hooks/useGeolocation'
import { constants } from '../../helpers/constants'
import { InteractionOutcomes } from 'types'
import { PopupModal } from 'components/modal/PopupModal'
import { SuccessModalContent } from 'components/modal/SuccessModalContent'
import { WarningModalContent } from 'components/modal/WarningModalContent'

export const Visit = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const {
        visitSubmissionHandler,
        setIsVisitedHandler,
        showWarningModalHandler
    } = useBindDispatch()

    const [searchParams] = useSearchParams()
    const fromURL = searchParams.get('from')

    const { clientDetailsPayload } = useAppSelector(
        (state) => state.clientDetailEnrollment
    )

    const { isVisited } = useAppSelector((state) => state.isVisited)
    const { showWarningModal } = useAppSelector((state) => state.showWarning)

    const showWarningModalCallback = useCallback(
        (status: boolean) => {
            showWarningModalHandler(status)
        },
        [showWarningModalHandler]
    )
    const { username } = getConnectUser()
    const { clientCode, villageId, clientName, origin } = useGetStorage(
        constants.CLIENT_FOR_VISIT_STORE
    )
    const { geolocation, geolocationTimer, loadingGeolocation } =
        useGeolocation({
            backUrl:
                fromURL ||
                `/village/${villageId}/client/${clientCode}/${
                    origin === constants.ORIGIN_CLIENT_DETAIL ? '' : 'previous'
                }`
        })

    useBackAction(
        fromURL ||
            `/village/${villageId}/client/${clientCode}/${
                origin === constants.ORIGIN_CLIENT_DETAIL ? '' : 'previous'
            }`
    )

    const storedClientDetails = useGetStorage(
        constants.CLIENT_DETAIL_LOCAL_STORE
    )
    const uuid = generateUuidv4()
    const surveyName = constants.FARMER_AWARENESS_SURVEY_NAME
    const surveyVersion = constants.FARMER_AWARENESS_SURVEY_VERSION

    const handleWarningModalOk = () => {
        showWarningModalCallback(false)
        setIsVisitedHandler(true)
        navigate(
            fromURL ||
                `/village/${villageId}/client/${clientCode}/${
                    origin === constants.ORIGIN_CLIENT_DETAIL ? '' : 'previous'
                }`,
            {
                replace: true
            }
        )
    }

    const warningModal = () => {
        return (
            <Modal
                okClickHandler={handleWarningModalOk}
                cancelClickHandler={() => showWarningModalCallback(false)}
                showModal={true}
                okButtonText={t('yes')}
                cancelButtonText={t('no')}
                data-testid="mark-delivery-modal"
                position={'center'}
                textHeader={t('visitWarningHeader')}
            >
                <WarningModalContent warningText={t('visitWarningBody')} />
            </Modal>
        )
    }

    const showWarningModalMemo = useMemo(() => {
        if (showWarningModal) {
            return warningModal()
        }
    }, [showWarningModal])
    const exitForm = () => {
        if (!isVisited) {
            showWarningModalCallback(true)
            return
        }
        setIsVisitedHandler(false)

        if (origin === constants.ORIGIN_CLIENT_DETAIL) {
            navigate(fromURL || `/village/${villageId}/client/${clientCode}`, {
                replace: true
            })
        } else {
            const clientDetailsUrl = `/village/${villageId}/client/${clientCode}/previous`
            const navigationUrl =
                !fromURL || fromURL.includes('client-search')
                    ? clientDetailsUrl
                    : (fromURL as string)

            navigate(navigationUrl, {
                replace: true
            })
        }
    }

    useEffect(() => {
        document.title = t('visit.pageTitle')
        showWarningModalCallback(false)
        setIsVisitedHandler(false)
    }, [])

    useEffect(() => {
        if (clientDetailsPayload) {
            const clientDetails: {
                firstname?: string
                lastname?: string
                zoneName?: string
                villageName?: string
            } = {
                ...clientDetailsPayload
            }
            const newClientDetails = {
                ...clientDetails,
                fullname: `${clientDetails?.firstname} ${clientDetails?.lastname}`,
                zone: clientDetails?.zoneName,
                village: clientDetails?.villageName
            }
            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify(newClientDetails)
            )
        }
    }, [clientDetailsPayload])

    const handleSubmit = (data: VisitFormState) => {
        const surveyData = { ...data, location: geolocation }

        const payload = {
            survey_data: surveyData,
            id: uuid,
            user_id: username,
            client_code: clientCode,
            survey_name: surveyName,
            survey_version: surveyVersion,
            date: `${new Date().toISOString().slice(0, -5)}Z`,
            outcome:
                data.wantsOtherVisit.toLowerCase() === 'yes'
                    ? InteractionOutcomes.FOLLOW_UP
                    : InteractionOutcomes.NOT_INTERESTED,
            village_id: villageId,
            status: storedClientDetails?.client_status
        }

        localStorage.setItem(
            constants.CLIENT_VISIT_INTERACTION,
            JSON.stringify(payload)
        )

        setIsVisitedHandler(true)

        visitSubmissionHandler(payload)
    }

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back backNavigation={exitForm} close={exitForm}>
                        {t('visit.pageTitle')}
                    </TopBar>
                </Container>
            </div>
            <VisitForm
                t={t}
                disable={loadingGeolocation}
                disabledDuration={geolocationTimer}
                handleSubmit={handleSubmit}
            />
            {isVisited && (
                <PopupModal closeModal={exitForm}>
                    <SuccessModalContent clientName={clientName} />
                </PopupModal>
            )}
            {showWarningModalMemo}
        </>
    )
}
