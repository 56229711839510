import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { PhoneIcon } from '../../assets'
import './card.scss'
import { CardClientContext } from '../../contexts'

export const ClientPhone = ({ children, ...props }) => {
    const { clientStatus } = useContext(CardClientContext)
    return (
        <div {...props}>
            <div className="card-phone-icon">
                <p className="card-phonenumber">
                    <span className="show-mobile">{clientStatus}</span>
                    <span className="hide-mobile">{children}</span>
                </p>
                {PhoneIcon({ color: '' })}
            </div>
        </div>
    )
}

ClientPhone.propTypes = {
    children: PropTypes.string
}
