import React, { Dispatch, FC, SetStateAction } from 'react'
import { Col, Container, Row } from 'components/layout'
import { Packet } from 'components/packet/Packet'
import './enrollForm.scss'
import { ProductInfo, SelectedPacket } from './enrollFormTypes'
import { sortHandler, constants } from 'helpers'

type PacketSelectionInterface = {
    selectedProduct: ProductInfo
    handlePacketSelection: Dispatch<SetStateAction<SelectedPacket[]>>
    preSelectedPackets?: SelectedPacket[]
}

export const PacketSelection: FC<PacketSelectionInterface> = ({
    selectedProduct,
    handlePacketSelection,
    preSelectedPackets
}) => {
    const packetNameFormat = () => {
        if (!selectedProduct) return { name: '', subName: '' }
        const [name, subName] = selectedProduct.name.split('(')
        return {
            name: name.trim(),
            subName: subName ? `(${subName.trim()}` : ''
        }
    }

    const convertToSelectedPackets = () =>
        selectedProduct.product_prices.map((productPrice) => ({
            pricebook_id: productPrice.pricebook.sf_id,
            product_id: selectedProduct.sf_id,
            price: productPrice.price?.toString(),
            size: productPrice.package_size?.toString(),
            size_display: productPrice.package_size_display,
            name: selectedProduct.name,
            hidePrice: selectedProduct.hide_price,
            unit: selectedProduct.unit
        }))

    const sortByPackageSize = () => {
        const packets = convertToSelectedPackets()

        return sortHandler(
            packets,
            'size_display',
            constants.SORT_TYPE.ALPHA_NUMERIC,
            constants.SORT_DIRECTION.DESCENDING
        ) as SelectedPacket[]
    }

    const filterPreSelectedPackets = () => {
        if (!preSelectedPackets) {
            return []
        }

        return preSelectedPackets.filter(
            (preSelectedPacket) =>
                preSelectedPacket.product_id === selectedProduct.sf_id
        )
    }

    const getPacketQuantity = (packet: SelectedPacket) => {
        const preSelectedPackets = filterPreSelectedPackets()
        let totalPreSelectedPackets = 0

        if (preSelectedPackets) {
            preSelectedPackets.forEach((preSelectedPacket) => {
                if (
                    preSelectedPacket.size === packet.size ||
                    preSelectedPacket.size_display === packet.size_display
                ) {
                    totalPreSelectedPackets += 1
                }
            })
        }

        return totalPreSelectedPackets
    }

    return (
        <div data-testid="packet-selection" className="packet-selection">
            <div
                className="packet-selection--section-header"
                style={{
                    backgroundImage: `url(${selectedProduct?.image_url})`
                }}
            ></div>
            <div className="packet-selection--main">
                <div className="packet-selection--heading">
                    <h2 className="packet-selection--heading-title">
                        {packetNameFormat().name}
                    </h2>
                    <p className="packet-selection--heading-subtitle">
                        {packetNameFormat().subName}
                    </p>
                </div>
                <div>
                    <Container>
                        <Row>
                            {selectedProduct &&
                                sortByPackageSize().map((productPacket) => {
                                    return (
                                        <Col
                                            md={3}
                                            col={6}
                                            key={productPacket.product_id}
                                        >
                                            <div className="packet-selection--single-packet">
                                                <Packet
                                                    productPacket={
                                                        productPacket
                                                    }
                                                    quantity={
                                                        getPacketQuantity(
                                                            productPacket
                                                        ) || 0
                                                    }
                                                    packetSelectionHandler={
                                                        handlePacketSelection
                                                    }
                                                    selectedProduct={{
                                                        hide_price:
                                                            selectedProduct.hide_price,
                                                        name: selectedProduct.name,
                                                        unit: selectedProduct.unit,
                                                        sf_id: selectedProduct.sf_id
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    )
                                })}
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}
