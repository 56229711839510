import { InteractionOutcomes } from '../types/interactionOutcomes'
import { DepositActionType } from 'storeTypes'

export interface HomeInterface {
    veCode: string
    country: string
}

type HomePayloadType = {
    rankingData?: {}
    name?: string
    user_id?: number
}

export enum ClientTypes {
    CurrentClient = 'current',
    PreviousClient = 'previous'
}

export enum DesiredOutcomes {
    ENROLLED = 'Enrolled',
    PAYMENT_2ND = 'Payment_2nd',
    PAYMENT_3RD = 'Payment_3rd',
    PAYMENT_FINISHER = 'Payment_finisher'
}

export type TargetPayloadType = {
    client_status: string
    client_code?: string
    firstname?: string
    lastname?: string
    fullname?: string
    duplicate?: boolean
    reversed_fullname?: string
    desired_outcome: DesiredOutcomes
    outcome?: InteractionOutcomes
    ve_code?: string
    country_name?: string
    model_version?: string
    generation_date?: string
    targeting_start_date?: string
    targeting_end_date?: string
    uuid?: string
    isCompleted: boolean
    village_id?: string
    client_type?: ClientTypes
    phone?: string
    isFinisher?: boolean
}

export type HomeReducerStateType = {
    userData: HomePayloadType[] | []
    loading: boolean
    error: string
}

export type TargetListReducerStateType = {
    [key: string]: {
        targetList: [] | TargetPayloadType[]
        loading: boolean
        processing: boolean
        error: string
        processedTargetList?: TargetPayloadType[]
        selectedFilter?: string
    }
}

export enum VeHomeEnum {
    FETCHING_VE_HOME_DATA = 'fetching ve home data',
    FETCH_CARD_INVENTORY = 'fetch card inventory',
    FETCH_VE_RANKING_DATA = 'fetch ve ranking data',
    FETCH_VE_HOME_DATA = 'fetch ve home data',
    FAIL_VE_HOME_REQUEST = 'fail ve home request',
    PRE_FETCHING_VE_RANKING_DATA = 'prefetch fetching ve ranking data',
    PRE_FETCHING_ENROLLMENT_FORM = 'pre fetching enrollment form',
    FETCH_ENROLLMENT_FORM = 'fetch enrollment form',
    FETCHING_TARGET_LIST = 'fetching target list',
    FETCH_TARGET_LIST = 'fetch target list',
    FAIL_TARGET_LIST_REQUEST = 'fail target list request',
    FETCH_PROCESSED_TARGET_LIST = 'fetch processed target list',
    FILTER_PROCESSED_TARGET_LIST = 'filter processed target list',
    NO_PROCESSED_TARGET_LIST = 'no processed target list'
}

type FetchingVeHomeDataType = {
    type: VeHomeEnum.FETCHING_VE_HOME_DATA
}

type FetchVeRankingDataType = {
    type: VeHomeEnum.FETCH_VE_RANKING_DATA
    payload: HomePayloadType
}

type FetchVeHomeDataType = {
    type: VeHomeEnum.FETCH_VE_HOME_DATA
    payload: HomePayloadType
}

type FailVeHomeDataRequest = {
    type: VeHomeEnum.FAIL_VE_HOME_REQUEST
    payload: string
}

type PreFetchingVeRankingDataType = {
    type: VeHomeEnum.PRE_FETCHING_VE_RANKING_DATA
}

type PreFetchingEnrollmentFormType = {
    type: VeHomeEnum.PRE_FETCHING_ENROLLMENT_FORM
}

type FetchEnrollmentFormType = {
    type: VeHomeEnum.FETCH_ENROLLMENT_FORM
    payload: HomePayloadType
}

type FetchingTargetListType = {
    type: VeHomeEnum.FETCHING_TARGET_LIST
    veCode?: string
}

type FetchTargetListType = {
    type: VeHomeEnum.FETCH_TARGET_LIST
    payload: TargetPayloadType[]
    veCode?: string
}

type FetchProcessedTargetListType = {
    type: VeHomeEnum.FETCH_PROCESSED_TARGET_LIST
    payload?: TargetPayloadType[]
    veCode?: string
}

type FailTargetListRequestType = {
    type: VeHomeEnum.FAIL_TARGET_LIST_REQUEST
    payload?: string
    veCode?: string
}

type FilterProcessedTargetListType = {
    type: VeHomeEnum.FILTER_PROCESSED_TARGET_LIST
    payload: {
        selectedFilter: string
        targetList: TargetPayloadType[]
    }
    veCode: string
}

type NoProcessedTargetListType = {
    type: VeHomeEnum.NO_PROCESSED_TARGET_LIST
    veCode: string
}

export type HomeActionType =
    | FetchingVeHomeDataType
    | FetchVeRankingDataType
    | FetchVeHomeDataType
    | FailVeHomeDataRequest
    | PreFetchingVeRankingDataType
    | PreFetchingEnrollmentFormType
    | FetchEnrollmentFormType
    | DepositActionType

export type TargetListActionType =
    | FetchingTargetListType
    | FetchTargetListType
    | FailTargetListRequestType
    | FetchProcessedTargetListType
    | FilterProcessedTargetListType
    | NoProcessedTargetListType
