import React from 'react'

type IconProps = {
    color?: string
    width?: string
    height?: string
}

const HomeIcon = () => (
    <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.71476 0.300469L17.4238 8.18447C17.7898 8.56047 17.9998 9.08447 17.9998 9.62347V17.9995C17.9998 19.1025 17.1528 19.9995 16.1118 19.9995H12.9998V10.9995C12.9998 10.4475 12.5528 9.99947 11.9998 9.99947H5.99976C5.44676 9.99947 4.99976 10.4475 4.99976 10.9995V19.9995H1.88876C0.847756 19.9995 -0.000244141 19.1025 -0.000244141 17.9995V9.62347C-0.000244141 9.08447 0.209756 8.56047 0.574756 8.18547L8.28476 0.300469C8.66176 -0.0845312 9.33776 -0.0845312 9.71476 0.300469ZM11 19H7V12H11V19Z"
            fill="#636363"
        />
    </svg>
)

const VillagesIcon = ({
    color = '#636363',
    width = '25',
    height = '24'
}: IconProps) => (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none">
        <path
            d="M8.52491 10.5517C8.48943 10.5517 8.45425 10.5585 8.42146 10.572C3.76546 12.4986 1.17612 19.3858 0.903284 22.6787C0.890316 22.8352 1.01604 22.9655 1.17309 22.9655H5.79886C5.96995 22.9655 6.09968 22.8122 6.08003 22.6422C5.67562 19.1447 8.025 13.5758 10.8349 11.341C11.0769 11.1486 10.9238 10.5517 10.6146 10.5517H8.52491Z"
            fill={color}
        />
        <path
            d="M14.4537 10.5517C14.4197 10.5517 14.3861 10.5578 14.3544 10.5704C10.2073 12.2165 7.36833 18.659 6.97835 22.6724C6.9629 22.8315 7.08966 22.9655 7.24949 22.9655H11.8718C12.0416 22.9655 12.1709 22.8139 12.1524 22.6452C11.8056 19.483 13.3069 13.6673 16.8325 11.2796C17.0893 11.1057 16.964 10.5517 16.6538 10.5517H14.4537Z"
            fill={color}
        />
        <path
            d="M20.5326 10.5517C20.4922 10.5517 20.4521 10.5605 20.4156 10.5779C16.1115 12.6268 13.3958 17.5678 13.3013 22.6895C13.2985 22.8418 13.4223 22.9655 13.5746 22.9655H17.9427C18.1118 22.9655 18.241 22.8153 18.2224 22.6472C17.8572 19.3363 19.3156 13.6565 23.0647 11.2125C23.3191 11.0467 23.2046 10.5517 22.9008 10.5517H20.5326Z"
            fill={color}
        />
        <path
            d="M1.85049 10.5517C1.17437 10.5517 0.49825 11.2145 0.342004 11.7604C0.335797 11.7821 0.333252 11.8045 0.333252 11.827V18.9655C0.571983 17.0557 2.56667 13.08 4.97654 11.2397C5.21495 11.0576 5.08237 10.5517 4.78238 10.5517H1.85049Z"
            fill={color}
        />
        <path
            d="M23.948 12.4031C24.1242 12.3051 24.3333 12.4375 24.3333 12.6391V21.8234C24.3333 21.849 24.3298 21.8741 24.3218 21.8984C24.2643 22.0729 23.9856 22.821 23.3946 22.9596C23.3769 22.9638 23.3586 22.9655 23.3404 22.9655H19.5057C19.3533 22.9655 19.2295 22.8427 19.2326 22.6904C19.2969 19.4673 20.4883 14.3264 23.948 12.4031Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0767 4.59625C19.9824 4.52813 19.8554 4.52651 19.7594 4.59222L17.4189 6.19364C17.3437 6.24506 17.2988 6.33025 17.2988 6.42131V9.44822C17.2988 9.60057 17.4223 9.72408 17.5746 9.72408H22.1264C22.2787 9.72408 22.4022 9.60057 22.4022 9.44822V6.41686C22.4022 6.32828 22.3597 6.24508 22.2879 6.19322L20.0767 4.59625ZM20.7471 6.82753C20.7471 6.67517 20.6236 6.55167 20.4712 6.55167H19.2298C19.0775 6.55167 18.954 6.67517 18.954 6.82753V8.06891C18.954 8.22126 19.0775 8.34477 19.2298 8.34477H20.4712C20.6236 8.34477 20.7471 8.22126 20.7471 8.06891V6.82753Z"
            fill={color}
        />
        <path
            d="M12.6251 2.79027C12.5319 2.72931 12.4112 2.7304 12.3191 2.79304L9.14365 4.95232C9.06812 5.00368 9.02291 5.0891 9.02291 5.18044V9.44822C9.02291 9.60057 9.14641 9.72408 9.29877 9.72408H11.3677C11.5201 9.72408 11.6436 9.60057 11.6436 9.44822V6.82753C11.6436 6.67517 11.7671 6.55167 11.9195 6.55167H13.0229C13.1753 6.55167 13.2988 6.67517 13.2988 6.82753V9.44822C13.2988 9.60057 13.4223 9.72408 13.5746 9.72408H15.7815C15.9339 9.72408 16.0574 9.60057 16.0574 9.44822V5.18365C16.0574 5.09054 16.0104 5.00372 15.9325 4.95276L12.6251 2.79027Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.32235 4.59935C5.22593 4.52971 5.09574 4.52971 4.99932 4.59935L2.79243 6.19322C2.72062 6.24508 2.67808 6.32828 2.67808 6.41686V9.44822C2.67808 9.60057 2.80159 9.72408 2.95394 9.72408H7.36773C7.52009 9.72408 7.6436 9.60057 7.6436 9.44822V6.41686C7.6436 6.32828 7.60106 6.24508 7.52925 6.19322L5.32235 4.59935ZM6.12636 6.82753C6.12636 6.67517 6.00285 6.55167 5.85049 6.55167H4.60912C4.45677 6.55167 4.33326 6.67517 4.33326 6.82753V8.06891C4.33326 8.22126 4.45677 8.34477 4.60912 8.34477H5.85049C6.00285 8.34477 6.12636 8.22126 6.12636 8.06891V6.82753Z"
            fill={color}
        />
        <path
            d="M2.12636 4.90436C2.12636 4.81314 2.17145 4.72782 2.24681 4.67643L5.00176 2.79806C5.09733 2.7329 5.22339 2.73428 5.31751 2.8015L7.94187 4.67605C8.01437 4.72783 8.05739 4.81144 8.05739 4.90053V5.18803C8.05739 5.41241 7.80377 5.54292 7.62119 5.41251L5.31751 3.76702C5.22339 3.69979 5.09733 3.69842 5.00176 3.76357L2.55762 5.43004C2.3745 5.55489 2.12636 5.42374 2.12636 5.20211V4.90436Z"
            fill={color}
        />
        <path
            d="M8.74704 3.79831C8.74704 3.70853 8.79073 3.62437 8.86415 3.57271L12.3153 1.14413C12.4091 1.07811 12.534 1.07705 12.6289 1.14147L16.2123 3.57303C16.2879 3.62437 16.3333 3.70987 16.3333 3.8013V3.97741C16.3333 4.19567 16.0918 4.32748 15.9082 4.20946L12.6231 2.09761C12.5308 2.03829 12.4122 2.03928 12.3209 2.10012L9.17593 4.19678C8.9926 4.319 8.74704 4.18758 8.74704 3.96725V3.79831Z"
            fill={color}
        />
        <path
            d="M20.0761 2.8015C19.982 2.73428 19.8559 2.7329 19.7604 2.79806L17.0054 4.67643C16.9301 4.72782 16.885 4.81314 16.885 4.90436V5.20211C16.885 5.42374 17.1331 5.55489 17.3162 5.43003L19.7604 3.76357C19.8559 3.69842 19.982 3.69979 20.0761 3.76702L22.3798 5.41251C22.5624 5.54292 22.816 5.41241 22.816 5.18803V4.90053C22.816 4.81144 22.773 4.72783 22.7005 4.67605L20.0761 2.8015Z"
            fill={color}
        />
        <path
            d="M8.52491 10.5517C8.48943 10.5517 8.45425 10.5585 8.42146 10.572C3.76546 12.4986 1.17612 19.3858 0.903284 22.6787C0.890316 22.8352 1.01604 22.9655 1.17309 22.9655H5.79886C5.96995 22.9655 6.09968 22.8122 6.08003 22.6422C5.67562 19.1447 8.025 13.5758 10.8349 11.341C11.0769 11.1486 10.9238 10.5517 10.6146 10.5517H8.52491Z"
            stroke={color}
            strokeWidth="0.275862"
        />
        <path
            d="M14.4537 10.5517C14.4197 10.5517 14.3861 10.5578 14.3544 10.5704C10.2073 12.2165 7.36833 18.659 6.97835 22.6724C6.9629 22.8315 7.08966 22.9655 7.24949 22.9655H11.8718C12.0416 22.9655 12.1709 22.8139 12.1524 22.6452C11.8056 19.483 13.3069 13.6673 16.8325 11.2796C17.0893 11.1057 16.964 10.5517 16.6538 10.5517H14.4537Z"
            stroke={color}
            strokeWidth="0.275862"
        />
        <path
            d="M20.5326 10.5517C20.4922 10.5517 20.4521 10.5605 20.4156 10.5779C16.1115 12.6268 13.3958 17.5678 13.3013 22.6895C13.2985 22.8418 13.4223 22.9655 13.5746 22.9655H17.9427C18.1118 22.9655 18.241 22.8153 18.2224 22.6472C17.8572 19.3363 19.3156 13.6565 23.0647 11.2125C23.3191 11.0467 23.2046 10.5517 22.9008 10.5517H20.5326Z"
            stroke={color}
            strokeWidth="0.275862"
        />
        <path
            d="M1.85049 10.5517C1.17437 10.5517 0.49825 11.2145 0.342004 11.7604C0.335797 11.7821 0.333252 11.8045 0.333252 11.827V18.9655C0.571983 17.0557 2.56667 13.08 4.97654 11.2397C5.21495 11.0576 5.08237 10.5517 4.78238 10.5517H1.85049Z"
            stroke={color}
            strokeWidth="0.275862"
        />
        <path
            d="M23.948 12.4031C24.1242 12.3051 24.3333 12.4375 24.3333 12.6391V21.8234C24.3333 21.849 24.3298 21.8741 24.3218 21.8984C24.2643 22.0729 23.9856 22.821 23.3946 22.9596C23.3769 22.9638 23.3586 22.9655 23.3404 22.9655H19.5057C19.3533 22.9655 19.2295 22.8427 19.2326 22.6904C19.2969 19.4673 20.4883 14.3264 23.948 12.4031Z"
            stroke={color}
            strokeWidth="0.275862"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0767 4.59625C19.9824 4.52813 19.8554 4.52651 19.7594 4.59222L17.4189 6.19364C17.3437 6.24506 17.2988 6.33025 17.2988 6.42131V9.44822C17.2988 9.60057 17.4223 9.72408 17.5746 9.72408H22.1264C22.2787 9.72408 22.4022 9.60057 22.4022 9.44822V6.41686C22.4022 6.32828 22.3597 6.24508 22.2879 6.19322L20.0767 4.59625ZM20.7471 6.82753C20.7471 6.67517 20.6236 6.55167 20.4712 6.55167H19.2298C19.0775 6.55167 18.954 6.67517 18.954 6.82753V8.06891C18.954 8.22126 19.0775 8.34477 19.2298 8.34477H20.4712C20.6236 8.34477 20.7471 8.22126 20.7471 8.06891V6.82753Z"
            stroke={color}
            strokeWidth="0.275862"
        />
        <path
            d="M12.6251 2.79027C12.5319 2.72931 12.4112 2.7304 12.3191 2.79304L9.14365 4.95232C9.06812 5.00368 9.02291 5.0891 9.02291 5.18044V9.44822C9.02291 9.60057 9.14641 9.72408 9.29877 9.72408H11.3677C11.5201 9.72408 11.6436 9.60057 11.6436 9.44822V6.82753C11.6436 6.67517 11.7671 6.55167 11.9195 6.55167H13.0229C13.1753 6.55167 13.2988 6.67517 13.2988 6.82753V9.44822C13.2988 9.60057 13.4223 9.72408 13.5746 9.72408H15.7815C15.9339 9.72408 16.0574 9.60057 16.0574 9.44822V5.18365C16.0574 5.09054 16.0104 5.00372 15.9325 4.95276L12.6251 2.79027Z"
            stroke={color}
            strokeWidth="0.275862"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.32235 4.59935C5.22593 4.52971 5.09574 4.52971 4.99932 4.59935L2.79243 6.19322C2.72062 6.24508 2.67808 6.32828 2.67808 6.41686V9.44822C2.67808 9.60057 2.80159 9.72408 2.95394 9.72408H7.36773C7.52009 9.72408 7.6436 9.60057 7.6436 9.44822V6.41686C7.6436 6.32828 7.60106 6.24508 7.52925 6.19322L5.32235 4.59935ZM6.12636 6.82753C6.12636 6.67517 6.00285 6.55167 5.85049 6.55167H4.60912C4.45677 6.55167 4.33326 6.67517 4.33326 6.82753V8.06891C4.33326 8.22126 4.45677 8.34477 4.60912 8.34477H5.85049C6.00285 8.34477 6.12636 8.22126 6.12636 8.06891V6.82753Z"
            stroke={color}
            strokeWidth="0.275862"
        />
        <path
            d="M2.12636 4.90436C2.12636 4.81314 2.17145 4.72782 2.24681 4.67643L5.00176 2.79806C5.09733 2.7329 5.22339 2.73428 5.31751 2.8015L7.94187 4.67605C8.01437 4.72783 8.05739 4.81144 8.05739 4.90053V5.18803C8.05739 5.41241 7.80377 5.54292 7.62119 5.41251L5.31751 3.76702C5.22339 3.69979 5.09733 3.69842 5.00176 3.76357L2.55762 5.43004C2.3745 5.55489 2.12636 5.42374 2.12636 5.20211V4.90436Z"
            stroke={color}
            strokeWidth="0.275862"
        />
        <path
            d="M8.74704 3.79831C8.74704 3.70853 8.79073 3.62437 8.86415 3.57271L12.3153 1.14413C12.4091 1.07811 12.534 1.07705 12.6289 1.14147L16.2123 3.57303C16.2879 3.62437 16.3333 3.70987 16.3333 3.8013V3.97741C16.3333 4.19567 16.0918 4.32748 15.9082 4.20946L12.6231 2.09761C12.5308 2.03829 12.4122 2.03928 12.3209 2.10012L9.17593 4.19678C8.9926 4.319 8.74704 4.18758 8.74704 3.96725V3.79831Z"
            stroke={color}
            strokeWidth="0.275862"
        />
        <path
            d="M20.0761 2.8015C19.982 2.73428 19.8559 2.7329 19.7604 2.79806L17.0054 4.67643C16.9301 4.72782 16.885 4.81314 16.885 4.90436V5.20211C16.885 5.42374 17.1331 5.55489 17.3162 5.43003L19.7604 3.76357C19.8559 3.69842 19.982 3.69979 20.0761 3.76702L22.3798 5.41251C22.5624 5.54292 22.816 5.41241 22.816 5.18803V4.90053C22.816 4.81144 22.773 4.72783 22.7005 4.67605L20.0761 2.8015Z"
            stroke={color}
            strokeWidth="0.275862"
        />
    </svg>
)

const ProfileIcon = () => (
    <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 4C11 6.206 9.206 8 7 8C4.794 8 3 6.206 3 4C3 1.794 4.794 0 7 0C9.206 0 11 1.794 11 4ZM14 17C14 17.552 13.553 18 13 18H1C0.447 18 0 17.552 0 17C0 13.14 3.141 10 7 10C10.859 10 14 13.14 14 17Z"
            fill="#636363"
        />
    </svg>
)

export const NetworkBarSvgs = {
    HomeIcon,
    VillagesIcon,
    ProfileIcon
}
