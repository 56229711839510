import { useCallback, useEffect, useMemo, useState } from 'react'
import { DownloadStatus } from 'types'
import { useBindDispatch } from './useBindDispatch'
import { useSelector } from 'react-redux'
import { RootState } from 'store/configStore'
import { useDownloadVillage } from './useDownloadVillage'
import { internetConnect } from 'helpers'

export const useDownloadVeData = (
    veCode: string,
    veVillageIds: string[] = []
) => {
    const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>(
        DownloadStatus.NOT_DOWNLOADED
    )
    const [isDownloadError, setIsDownloadError] = useState(false)

    const { fetchTargetListHandler, veDepositsHandler } = useBindDispatch()
    const targetListLoadingData = useSelector(
        ({ targetList }: RootState) => targetList[veCode] || {}
    )
    const veDepositsLoadingData = useSelector(
        ({ veDeposit }: RootState) => veDeposit[veCode] || {}
    )

    const { downloadStatus: villageDownloadStatus, fetchVillageData } =
        useDownloadVillage(veVillageIds)

    const fetchVeData = useCallback(async () => {
        const internetConnection = internetConnect()

        if (!internetConnection) {
            setIsDownloadError(true)
            return
        }

        if (downloadStatus === DownloadStatus.NOT_DOWNLOADED) {
            setDownloadStatus(DownloadStatus.DOWNLOADING)
        }
        const promises: unknown[] = []
        promises.push(
            fetchTargetListHandler({
                veCode
            })
        )
        promises.push(veDepositsHandler({ veCode }))
        if (villageDownloadStatus === DownloadStatus.NOT_DOWNLOADED) {
            promises.push(fetchVillageData())
        }
        await Promise.all(promises)
    }, [downloadStatus, veCode, villageDownloadStatus])

    const isLoaded = useMemo(() => {
        let hasError = false
        const result =
            [targetListLoadingData, veDepositsLoadingData].every((state) => {
                if (state?.error) {
                    hasError = true
                }
                return state?.loading === false && !state?.error
            }) && villageDownloadStatus === DownloadStatus.DOWNLOADED
        if (hasError && downloadStatus === DownloadStatus.DOWNLOADING) {
            setDownloadStatus(DownloadStatus.NOT_DOWNLOADED)
        }
        return result
    }, [targetListLoadingData, veDepositsLoadingData, villageDownloadStatus])

    const updateDownloadStatus = useCallback(async () => {
        const localDownloaded = await localStorage.getItem(
            `ve-${veCode}-download_status`
        )
        const isPrevDownloaded =
            Number(localDownloaded) === DownloadStatus.DOWNLOADED
        if (isLoaded) {
            await localStorage.setItem(
                `ve-${veCode}-download_status`,
                JSON.stringify(DownloadStatus.DOWNLOADED)
            )
        }
        if (isPrevDownloaded || isLoaded) {
            setDownloadStatus(DownloadStatus.DOWNLOADED)
        } else if (downloadStatus !== DownloadStatus.NOT_DOWNLOADED) {
            setDownloadStatus(DownloadStatus.NOT_DOWNLOADED)
        }
    }, [veCode, isLoaded])

    useEffect(() => {
        updateDownloadStatus()
    }, [isLoaded, veCode])

    useEffect(() => {
        if (isDownloadError) {
            const timer = setTimeout(() => {
                setIsDownloadError(false)
            }, 2000)

            return () => clearTimeout(timer)
        }
    }, [isDownloadError])

    return {
        downloadStatus,
        fetchVeData,
        isDownloadError
    }
}
