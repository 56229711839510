import { ContinueButton } from 'components/button/ContinueButton'
import React, { FC, useEffect, useState } from 'react'
import '../../components/enrollForm/referrals/referrals.scss'
import { ButtonStylesEnum } from 'types/buttonStylesEnum'
import { PlusIcon } from 'assets'
import { ReferralOptions } from '../../components/enrollForm/referrals/Referrals'
import { useGetStorage, useValidation } from 'hooks'
import { useTranslation } from 'react-i18next'
import { ClientReferralsProps } from 'types/clientReferrals'
import { TextField } from 'components/enrollForm/TextField'
import { getConnectUser, validationRules } from 'helpers'

export const ClientReferrals: FC<ClientReferralsProps> = ({
    selectedReferrerOption,
    setSelectedReferrerOption,
    inputText,
    onChangeHandler,
    nextStepHandler
}) => {
    const { t } = useTranslation()
    const referralsOptions = useGetStorage('country_config')?.referrals || []
    const { country } = getConnectUser()
    const [onSecondScreen, setShowSecondScreen] = useState(false)

    const referralInputValidationRule = validationRules({
        country,
        referralsOptions
    })

    const { errors, validateForm } = useValidation(
        { referrer: inputText.referrer?.trim() },
        referralInputValidationRule
    )
    const shouldShowError = errors.referrer
    const shouldShowContinueButton =
        !shouldShowError && (!onSecondScreen || inputText.referrer)

    const shouldShowNextScreen = !(
        selectedReferrerOption.option ||
        onSecondScreen ||
        referralsOptions.length === 0
    )

    const isContinueButton = !shouldShowNextScreen

    const handleSubmit = () => {
        if (shouldShowNextScreen) {
            setShowSecondScreen(true)
            return
        }

        nextStepHandler()
    }

    const continueBtnProps = {
        style: isContinueButton
            ? ButtonStylesEnum.PRIMARY
            : ButtonStylesEnum.OUTLINE,
        btnText: !isContinueButton ? t('referrals.form.new_referral_btn') : '',
        icon: !selectedReferrerOption.option ? <PlusIcon /> : undefined,
        onClick: handleSubmit,
        className: 'enrollment-base--continue-btn referrals--continue-btn'
    }

    useEffect(() => {
        if (inputText.referrer && inputText.referrer.length > 0) {
            validateForm()
        }
    }, [inputText.referrer, country])

    return (
        <>
            <p
                className="enrollment--input-label"
                style={{ textAlign: 'center' }}
            >
                {t('referrals.title')}
            </p>
            {!onSecondScreen ? (
                <ReferralOptions
                    referralsOptions={referralsOptions}
                    selectedReferrerOption={selectedReferrerOption}
                    setSelectedReferrerOption={setSelectedReferrerOption}
                />
            ) : (
                <>
                    <TextField
                        key={'referrer'}
                        attribute="referrer"
                        inputValue={inputText}
                        onChangeHandler={onChangeHandler}
                        icon={<></>}
                        formLabel={t('referrals.form.new_grouping_label')}
                        className="referrals-form--input-field"
                        maxLength={50}
                    />
                    {shouldShowError && (
                        <p className="enrollment--input-error">
                            {errors.referrer}
                        </p>
                    )}
                </>
            )}
            {shouldShowContinueButton && (
                <ContinueButton {...continueBtnProps} />
            )}
        </>
    )
}
