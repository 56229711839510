import {
    constants,
    defaultSortConfig,
    getConnectUser,
    sortAndGroupList
} from 'helpers'
import { useAppSelector, useBindDispatch } from 'hooks'
import { useAnimation } from 'hooks/useAnimation'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { CardInventoryResponse } from 'storeTypes'
import { VeIcon } from 'assets'
import './card.scss'
import {
    Col,
    Container,
    Row,
    Spinner,
    TopBar,
    CardInventoryItem,
    CardInventorySummary,
    EmptyList
} from 'components'
import { useLocation } from 'react-router-dom'
import { colors } from 'styles/colors'

export const CardInventory = () => {
    const { t } = useTranslation()
    const animate = useAnimation()
    const nodeRef = useRef(null)
    const location = useLocation()

    const [cardInventoryData, setCardInventoryData] = useState<
        [string, CardInventoryResponse[]][]
    >([])

    const { username } = getConnectUser()

    const { cardInventory, loading } = useAppSelector(
        (state) => state.cardInventoryState
    )

    const { veCardInventoryHandler } = useBindDispatch()
    useEffect(() => {
        if (!cardInventory) {
            veCardInventoryHandler(username)
        } else {
            const groupedCardInventory = sortAndGroupList(cardInventory, {
                ...defaultSortConfig,
                sortKey: 'date_distributed'
            })
            setCardInventoryData(Object.entries(groupedCardInventory))
        }
    }, [cardInventory, loading])

    const summaryInventory = useMemo(() => {
        let totalAmountReceived = 0
        let totalAmountSold = 0
        let totalAmountRemaining = 0
        let totalAmountInactiveCards = 0
        let nbCardsReceived = 0
        let nbCardsSold = 0
        let nbCardsRemaining = 0
        let nbInactiveCards = 0
        cardInventoryData.forEach(([, cardInventoryList]) => {
            cardInventoryList.forEach((inventory) => {
                totalAmountReceived +=
                    inventory.amount * inventory.cards_in_packet
                totalAmountSold += inventory.used_cards * inventory.amount
                totalAmountRemaining +=
                    inventory.active_cards * inventory.amount
                totalAmountInactiveCards +=
                    inventory.unusable_cards * inventory.amount
                nbCardsReceived += inventory.cards_in_packet
                nbCardsSold += inventory.used_cards
                nbCardsRemaining += inventory.active_cards
                nbInactiveCards += inventory.unusable_cards
            })
        })

        return (
            <div className="summary-parent-container">
                <CardInventorySummary
                    amount={totalAmountReceived}
                    title={t('card.cardsReceived')}
                    numberOfCards={nbCardsReceived}
                    color={colors.black}
                />
                <CardInventorySummary
                    amount={totalAmountSold}
                    title={t('card.cardsSold')}
                    numberOfCards={nbCardsSold}
                    color={colors.primary}
                />
                <CardInventorySummary
                    amount={totalAmountRemaining}
                    title={t('card.cardsRemaining')}
                    numberOfCards={nbCardsRemaining}
                    color={colors.blue}
                />
                {nbInactiveCards > 0 && (
                    <CardInventorySummary
                        amount={totalAmountInactiveCards}
                        title={t('card.cardsInactive')}
                        numberOfCards={nbInactiveCards}
                        color={colors.grey}
                    />
                )}
            </div>
        )
    }, [cardInventoryData])

    return (
        <>
            <CSSTransition
                unmountOnExit
                timeout={constants.ANIMATION_TIMEOUT}
                in={animate}
                classNames="generic"
                appear
                nodeRef={nodeRef}
            >
                <>
                    <Container>
                        <TopBar iconColor="#000000" transparentTopBar back>
                            <div className="topbar-name-container">
                                <VeIcon
                                    width="16"
                                    height="16"
                                    color={colors.blue}
                                />
                                <p>{location.state?.name}</p>
                            </div>
                        </TopBar>
                    </Container>
                    <div className="card-inventory" ref={nodeRef}>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <h2 className="client-payment--header card-inventory--header">
                                        {t('card.inventory')}
                                    </h2>
                                    {loading ? (
                                        <Spinner
                                            size="50"
                                            fullscreen
                                            pageSpinner
                                        />
                                    ) : cardInventory &&
                                      cardInventory.length > 0 ? (
                                        <>
                                            {summaryInventory}
                                            <div className="list card-inventory--list">
                                                {cardInventoryData.map(
                                                    (
                                                        [
                                                            dateDistributed,
                                                            cardInventoryList
                                                        ],
                                                        id
                                                    ) => (
                                                        <div
                                                            key={id}
                                                            className="list--row"
                                                        >
                                                            <p className="list--row--title">
                                                                {t(
                                                                    'fullDateMonthYear',
                                                                    {
                                                                        date: new Date(
                                                                            dateDistributed
                                                                        )
                                                                    }
                                                                )}
                                                            </p>
                                                            {cardInventoryList.map(
                                                                (inventory) => (
                                                                    <CardInventoryItem
                                                                        key={
                                                                            inventory.name
                                                                        }
                                                                        inventory={
                                                                            inventory
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <EmptyList
                                            message={t(
                                                'notAvailable.cardInventory'
                                            )}
                                            className="empty-list--card-inventory"
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
            </CSSTransition>
        </>
    )
}
