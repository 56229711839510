import { MyAgroLogo } from 'assets'
import transactionSourceIcons from 'assets/svg/transactionSourceIcons'
import React, { FC } from 'react'
import { TransactionKind, TransactionSource } from 'storeTypes'
import { TransactionKindConfigType, TransactionSourceConfigType } from './types'

export const PaymentSourceIcons: FC<{
    type: TransactionKind
    source: TransactionSource
}> = ({ type, source }) => {
    const {
        OrangeMoneyPaymentIcon,
        WavePaymentIcon,
        ColoredCardPaymentIcon,
        PackageIcon,
        FarmerHatIcon
    } = transactionSourceIcons

    const sharedKeys = Object.values(TransactionKind).filter(
        (value) => value !== TransactionKind.AWARD
    )

    const paymentMomoIconsConfig: TransactionSourceConfigType = {
        [TransactionSource.WAVE]: {
            icon: <WavePaymentIcon />,
            labelText: 'wave-payment-icon'
        },
        [TransactionSource.ORANGE_MONEY]: {
            icon: <OrangeMoneyPaymentIcon />,
            labelText: 'orange-payment-icon'
        }
    }

    const otherPaymentIconsConfig: TransactionKindConfigType = {
        ...sharedKeys.reduce((acc, key) => {
            acc[key] = {
                icon: <ColoredCardPaymentIcon />,
                labelText: 'card-payment-icon'
            }
            return acc
        }, {} as TransactionKindConfigType),
        [TransactionKind.AWARD]: {
            icon: <MyAgroLogo size={'24'} color={'#16A34A'} />,
            labelText: 'award-credit-icon'
        },
        [TransactionKind.AWARD_REVERSAL]: {
            icon: <MyAgroLogo size={'24'} color={'#A3743B'} />,
            labelText: 'award-credit-icon'
        },
        [TransactionKind.DELIVERY]: {
            icon: <FarmerHatIcon />,
            labelText: 'farmer-hat-icon'
        },
        [TransactionKind.REIMBURSEMENT]: {
            icon: <FarmerHatIcon />,
            labelText: 'farmer-hat-icon'
        }
    }
    return (
        <div>
            {type === TransactionKind.MOBILE_MONEY_PAYMENT ||
            type === TransactionKind.MOBILE_MONEY_PAYMENT_REVERSAL ? (
                <div aria-label={paymentMomoIconsConfig[source].labelText}>
                    {paymentMomoIconsConfig[source].icon}
                </div>
            ) : (
                <div className="list--item--right-side">
                    {TransactionKind.DELIVERY === type && (
                        <div aria-label="package-delivery-icon">
                            <PackageIcon />
                        </div>
                    )}
                    <div aria-label={otherPaymentIconsConfig[type]?.labelText}>
                        {otherPaymentIconsConfig[type]?.icon}
                    </div>
                </div>
            )}
        </div>
    )
}
