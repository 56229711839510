import React, { useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { TopBar, Spinner, TimedAlert, RemainDepositBadge } from 'components'
import { useAppSelector, useBindDispatch } from 'hooks'
import { amountFormatter, constants, getConnectUser } from 'helpers'
import { useTranslation } from 'react-i18next'
import { DepositDetailsType } from 'storeTypes'
import { SingleDeposit } from './SingleDeposit'
import { VeIcon } from 'assets'
import { useSearchParams, useNavigate } from 'react-router-dom'

export const Deposits = () => {
    const { t } = useTranslation()
    const navigator = useNavigate()
    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const nodeRef = useRef(null)
    const { username } = getConnectUser()
    const { veDepositsHandler } = useBindDispatch()
    const {
        loading,
        veDepositData,
        error: depositsError
    } = useAppSelector((store) => store.veDeposit[username] || {})
    const { userData } = useAppSelector((store) => store.home)
    useEffect(() => {
        setAnimate(true)
        if (!veDepositData) {
            veDepositsHandler({ veCode: username })
        }
    }, [])
    const [veNameParam] = useSearchParams()
    const veName = veNameParam.get('ve_name')

    const { name } = userData[0] || { name: veName }

    const depositsRender = ({
        depositList,
        remainingDepositTotal
    }: {
        depositList: DepositDetailsType[]
        remainingDepositTotal: number
    }) => (
        <CSSTransition
            unmountOnExit
            timeout={constants.ANIMATION_TIMEOUT}
            in={animate}
            classNames="generic"
            appear
            onEnter={() => setAnimationList(true)}
            nodeRef={nodeRef}
        >
            <div className="deposits--parent" ref={nodeRef}>
                <div className="deposits--container">
                    <div
                        aria-label="deposits-card"
                        className={
                            animationList
                                ? 'animate-show-2 animate-showed-2'
                                : 'animate-show-2'
                        }
                    >
                        <h2 className="deposits--header">
                            {t('deposits.pageTitle')}
                        </h2>
                        {remainingDepositTotal > 0 && (
                            <div className="deposits--remain-container">
                                <RemainDepositBadge
                                    totalRemainingDeposit={amountFormatter(
                                        remainingDepositTotal
                                    )}
                                />
                            </div>
                        )}
                        {depositList.map(
                            (
                                {
                                    amount,
                                    reference_code: referenceCode,
                                    deposit_date: depositDate,
                                    source
                                },
                                idx
                            ) => (
                                <SingleDeposit
                                    key={idx}
                                    deposit_date={depositDate}
                                    amount={amount}
                                    reference_code={referenceCode}
                                    source={source}
                                    t={t}
                                />
                            )
                        )}
                    </div>
                </div>
            </div>
        </CSSTransition>
    )

    const depositPage = loading ? (
        <Spinner size={'90'} pageSpinner={true} />
    ) : depositsError ? (
        <TimedAlert type="floating" status="error">
            {depositsError}
        </TimedAlert>
    ) : (
        depositsRender({
            depositList: veDepositData?.deposits || [],
            remainingDepositTotal: veDepositData?.gap || 0
        })
    )

    return (
        <>
            <div>
                <TopBar
                    iconColor="#000000"
                    transparentTopBar
                    back
                    backNavigation={() => navigator('/profile')}
                >
                    <div className="topbar--title">
                        <VeIcon width="20" height="21" color="#2C70E3" />
                        {name}
                    </div>
                </TopBar>
            </div>
            {depositPage}
        </>
    )
}
