import { handleTargetFilter } from 'actions'
import {
    TargetListReducerStateType,
    TargetPayloadType,
    TargetListActionType,
    VeHomeEnum
} from 'storeTypes'

const initialTargetListState: TargetListReducerStateType = {}

const hideIncompleteTargets = (targetList: TargetPayloadType[]) =>
    targetList.filter(
        (target: TargetPayloadType) => target?.firstname && target?.lastname
    )

export const targetListReducer = (
    state: TargetListReducerStateType = initialTargetListState,
    action: TargetListActionType
): TargetListReducerStateType => {
    switch (action.type) {
        case VeHomeEnum.FETCHING_TARGET_LIST:
            return {
                ...state,
                [action.veCode || '']: {
                    loading: true,
                    processing: true,
                    error: '',
                    targetList: [],
                    processedTargetList: []
                }
            }
        case VeHomeEnum.FETCH_TARGET_LIST:
            return {
                ...state,
                [action.veCode || '']: {
                    loading: false,
                    processing: true,
                    error: '',
                    targetList: action.payload || [],
                    processedTargetList: []
                }
            }
        case VeHomeEnum.FAIL_TARGET_LIST_REQUEST:
            return {
                ...state,
                [action.veCode || '']: {
                    loading: false,
                    processing: false,
                    error: action.payload || 'Un problème est survenu',
                    targetList: [],
                    processedTargetList: []
                }
            }
        case VeHomeEnum.FETCH_PROCESSED_TARGET_LIST: {
            const processedTargets = action.payload || []
            const cleanedTargetList = hideIncompleteTargets(processedTargets)
            return {
                ...state,
                [action.veCode || '']: {
                    ...state[action.veCode || ''],
                    processing: false,
                    processedTargetList: cleanedTargetList || []
                }
            }
        }
        case VeHomeEnum.FILTER_PROCESSED_TARGET_LIST: {
            const processedTargets =
                handleTargetFilter(
                    action.payload.selectedFilter,
                    action.payload.targetList
                ) || []
            const cleanedTargetList = hideIncompleteTargets(processedTargets)
            return {
                ...state,
                [action.veCode || '']: {
                    ...state[action.veCode || ''],
                    processedTargetList: cleanedTargetList,
                    selectedFilter: action.payload.selectedFilter
                }
            }
        }
        case VeHomeEnum.NO_PROCESSED_TARGET_LIST:
            return {
                ...state,
                [action.veCode || '']: {
                    ...state[action.veCode || ''],
                    processing: false
                }
            }
        default:
            return state
    }
}
