import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import './tab.scss'

export const TabChild = ({
    children,
    title,
    activeTab,
    setActiveTab,
    idx,
    count,
    className,
    activeTabHandler,
    onClickHandler,
    ...props
}) => {
    const ref = useRef()
    const handleSetActiveTab = (key) => {
        setActiveTab({ status: true, key })
        // store active tab in local storage
        localStorage.setItem(
            'active_tab',
            JSON.stringify({ status: true, key })
        )
        activeTabHandler({ status: true, key })
        onClickHandler()
    }
    const tabChildActive = activeTab.key === idx && 'tab-child-primary--active'

    useEffect(() => {
        activeTabHandler({ status: true, key: activeTab.key })
    }, [])

    return (
        <div className="tab-child-primary">
            <div
                className={[`${tabChildActive}`, className].join(' ')}
                onClick={() => handleSetActiveTab(idx)}
                {...props}
            >
                <p className="tab-child-link">
                    {title}
                    {(count || count === 0) && <span> ({count})</span>}
                </p>
            </div>
            {activeTab.key === idx && (
                <div
                    className="tab-child-body"
                    id={`tab-${activeTab.key}`}
                    ref={ref}
                >
                    {children}
                </div>
            )}
        </div>
    )
}

TabChild.propTypes = {
    children: PropTypes.any,
    number: PropTypes.number,
    count: PropTypes.number,
    activeTab: PropTypes.object,
    setActiveTab: PropTypes.func,
    idx: PropTypes.number,
    title: PropTypes.string,
    className: PropTypes.string,
    activeTabHandler: PropTypes.func,
    onClickHandler: PropTypes.func
}
