import React from 'react'
import { getConnectUser, STAFF_ROLES } from 'helpers'
import { NavigateOptions, useNavigate } from 'react-router-dom'
import { useLogout } from 'hooks/useLogout'

export const useBaseNavigation = (basePath = '') => {
    const navigate = useNavigate()
    const user = getConnectUser()
    const { logout } = useLogout()

    if (user.role === STAFF_ROLES.FC) {
        basePath = '/fc'
    }

    const navigateWithBasePath = React.useCallback(
        (path: string, options?: NavigateOptions) => {
            const user = getConnectUser()
            const currentPath = window.location.pathname

            if (user.role || currentPath === '/login') {
                navigate(`${basePath}${path}`, options)
            } else {
                logout()
            }
        },
        [basePath, navigate]
    )

    return navigateWithBasePath
}
