const normalizeText = (text: string) =>
    text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export function searchBy<T>(
    items: T[],
    attributes: (keyof T)[],
    query: string
) {
    return (
        items?.filter((item) =>
            attributes.some((attribute) => {
                const value = normalizeText(String(item[attribute]))
                const terms = query.split(/\s+/)

                return terms.every((term) =>
                    value
                        .toLowerCase()
                        .includes(normalizeText(term.toLowerCase()))
                )
            })
        ) || []
    )
}
