import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Card, Spinner, EmptyList } from '../../components'
import { useBindDispatch, useGetStorage } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { constants, getConnectUser } from '../../helpers/'
import { findPermission } from '../../helpers/findPermission'
import { groupBy, partition } from 'lodash'
import { ClientOrder } from './ClientOrder'

export const VillageClientPacket = ({
    goalItems,
    modifyHandler,
    allowModification,
    finisher
}) => {
    const { t } = useTranslation()
    const { productListHandler } = useBindDispatch()
    const [localGoalItems, setLocalGoalItems] = useState([])
    const { productListData, loading, preFetchLoading } = useSelector(
        (store) => store.enrollment
    )
    const { zoneSFID } = getConnectUser()

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const { UPDATE_ORDER } = constants

    const canUpdatePackage =
        findPermission(userPermissions, UPDATE_ORDER) && allowModification

    useEffect(() => {
        if (productListData?.length > 0) {
            processGoalItems(goalItems)
        }
    }, [goalItems, productListData])

    useEffect(() => {
        if (productListData?.length === 0 && !preFetchLoading) {
            productListHandler({ zoneSFID })
        }
    }, [])

    const findProduct = (productId) => {
        const zoneProductList = productListData.find(
            (product) => product.zone_sf_id === zoneSFID
        )

        const product = zoneProductList?.product_list?.find(
            (product) => product.sf_id === productId
        )

        return product
    }

    const processGoalItems = (goalItems = []) => {
        const goalItemsMap = new Map()
        goalItems.forEach((goalItem) => {
            if (goalItem.season_active === false) return
            // After modification delivered is undefined
            // Making sure we get a boolean value
            const delivered = goalItem.delivered === true
            const key = `${goalItem.product.id}-${goalItem.price}-${delivered}`
            const existingGoalItem = goalItemsMap.get(key)
            if (existingGoalItem) {
                goalItemsMap.set(key, {
                    ...existingGoalItem,
                    nbOfGoalItems: ++existingGoalItem.nbOfGoalItems
                })
            } else {
                goalItemsMap.set(key, {
                    productId: goalItem.product.id,
                    productName: goalItem.product.name,
                    price: goalItem.price,
                    imageUrl: findProduct(goalItem.product.id)?.image_url || '',
                    nbOfGoalItems: 1,
                    scheduled_delivery_date: goalItem.scheduled_delivery_date,
                    delivered
                })
            }
        })
        setLocalGoalItems(Array.from(goalItemsMap.values()))
    }

    const processedGoalItems = useMemo(() => {
        const [unDeliveredGoalItems, deliveredGoalItems] = partition(
            localGoalItems,
            (goalItem) =>
                goalItem.delivered === undefined || !goalItem.delivered
        )

        const goalItemsByDate = groupBy(
            unDeliveredGoalItems,
            'scheduled_delivery_date'
        )

        const sortedDates = Object.keys(goalItemsByDate).sort((a, b) => {
            if (a === 'null' || a === 'undefined' || !a) return 1
            if (b === 'null' || b === 'undefined' || !b) return -1
            return new Date(b) - new Date(a)
        })
        const sortedGoalItemsByDate = {}
        sortedDates.forEach((date) => {
            sortedGoalItemsByDate[date] = goalItemsByDate[date]
        })
        return { deliveredGoalItems, sortedGoalItemsByDate }
    }, [localGoalItems])

    if (loading) {
        return (
            <Spinner
                aria-label="loading-indicator"
                size="90"
                pageSpinner={true}
                fullscreen={true}
            />
        )
    }

    const displayFilteredGoalItems = (items) => {
        const { deliveredGoalItems, sortedGoalItemsByDate: goalItemsByDate } =
            items
        return (
            <>
                {Object.keys(goalItemsByDate).map((key, index) => (
                    <div key={index}>
                        <ClientOrder
                            goalItems={goalItemsByDate[key]}
                            t={t}
                            finisher={finisher}
                            canUpdatePackage={canUpdatePackage}
                            modifyHandler={modifyHandler}
                        />
                    </div>
                ))}
                {deliveredGoalItems.length > 0 && (
                    <ClientOrder
                        goalItems={deliveredGoalItems}
                        t={t}
                        finisher={finisher}
                        canUpdatePackage={canUpdatePackage}
                        modifyHandler={modifyHandler}
                    />
                )}
            </>
        )
    }

    return (
        <div className="village-client-packet-parent">
            <Card shadow className="no-lateral-padding">
                <div className="village-client-details-padding">
                    <div>
                        <p className="village-client-packet-header">
                            {t('clientDetails.packetsTitle')}
                        </p>
                    </div>
                    {localGoalItems.length > 0 ? (
                        displayFilteredGoalItems(processedGoalItems)
                    ) : (
                        <EmptyList message={t('notAvailable.packets')} />
                    )}
                </div>
            </Card>
        </div>
    )
}

VillageClientPacket.propTypes = {
    goalItems: PropTypes.array,
    modifyHandler: PropTypes.func,
    allowModification: PropTypes.bool,
    finisher: PropTypes.bool
}
