import React from 'react'
import { Options, Splide, SplideSlide } from '@splidejs/react-splide'
import { Grid } from '@splidejs/splide-extension-grid'
import '@splidejs/react-splide/css'
import {
    PaginationData,
    PaginationItem,
    Splide as SplideType
} from '@splidejs/splide/dist/types'
import { CardProduct } from 'components/card/CardProduct'
import {
    ProductInfo,
    SelectedPacket,
    ZoneInfoTemplate
} from './enrollFormTypes'
import { constants, isDateExpired } from 'helpers'
import './enrollForm.scss'
import { useAppSelector, useGetStorage, useScreenSize } from 'hooks'
import { Spinner } from 'components/spinner/Spinner'
import { ContinueButton } from 'components/button/ContinueButton'
import { findPermission } from 'helpers/findPermission'

type ProductSelectionProps = {
    onProductSelected: (product: ProductInfo) => void
    selectedPackets?: SelectedPacket[]
    zoneProductsInfo?: ZoneInfoTemplate
    onClickHandler?: VoidFunction
    btnText?: string
}

export const ProductSelection = ({
    selectedPackets = [],
    onProductSelected,
    zoneProductsInfo,
    onClickHandler,
    btnText
}: ProductSelectionProps) => {
    const screenSize = useScreenSize()

    const { loading } = useAppSelector((state) => state.enrollment)

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)

    const canViewNewEnrollmentFlow = findPermission(
        userPermissions,
        constants.CAN_VIEW_NEW_ENROLLMENT_FLOW
    )

    const isMobile = screenSize.screenWidth < constants.MOBILE_SIZE
    const splideConfig: Options = {
        perMove: 1,
        perPage: 1,
        pagination: true,
        paginationDirection: 'ltr',
        autoHeight: true,
        autoWidth: false,
        flickPower: 10,
        width: screenSize.screenWidth * 0.9,
        arrows: false,
        grid: {
            rows: 3,
            cols: 2,
            gap: { row: '5%' }
        }
    }
    if (!isMobile) {
        splideConfig.width = undefined
        splideConfig.autoWidth = true
    }

    const onPaginationMounted = (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        splide: SplideType,
        data: PaginationData,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        item: PaginationItem
    ) => {
        data.list.classList.add('product-selection-custom-pagination')
    }

    const currentClient = useGetStorage(constants.CLIENT_FOR_ENROLLMENT_STORE)

    const buildProductList = (data: { product_list: ProductInfo[] }) => {
        if (data?.product_list?.length) {
            let filteredProducts = null

            if (
                currentClient?.form_type === 'modification' ||
                currentClient?.modification ===
                    constants.CLIENT_MODIFICATION_TYPES.PACKAGES
            ) {
                // filter expired products that are not in the client goal items
                filteredProducts = data.product_list.filter(
                    (product) =>
                        !product.end_date ||
                        (product.end_date &&
                            (!isDateExpired(product.end_date) ||
                                currentClient?.goal_items?.some(
                                    (goalItem: { product: { id: string } }) =>
                                        goalItem.product.id === product.sf_id
                                )))
                )
            } else {
                filteredProducts = data.product_list.filter(
                    (product) =>
                        !product.end_date ||
                        (product.end_date && !isDateExpired(product.end_date))
                )
            }
            return filteredProducts
        } else {
            return []
        }
    }

    return (
        <>
            {loading ? (
                <Spinner size="90" pageSpinner={true} fullscreen={true} />
            ) : (
                <div className="enrollment-button--packages">
                    {buildProductList(zoneProductsInfo as ZoneInfoTemplate)
                        .length > 0 && (
                        <Splide
                            onPaginationMounted={onPaginationMounted}
                            extensions={{ Grid }}
                            options={splideConfig}
                        >
                            {buildProductList(
                                zoneProductsInfo as ZoneInfoTemplate
                            ).map((product: ProductInfo) => {
                                // Count number of selected packets for this product
                                const nbSelectedUnits = selectedPackets?.reduce(
                                    (acc, packet) =>
                                        packet.product_id === product.sf_id
                                            ? acc + 1
                                            : acc,
                                    0
                                )
                                return (
                                    <SplideSlide key={product.sf_id}>
                                        <CardProduct
                                            product={product}
                                            onClick={(product) => {
                                                onProductSelected(product)
                                            }}
                                            nbSelectedUnits={nbSelectedUnits}
                                        />
                                    </SplideSlide>
                                )
                            })}
                        </Splide>
                    )}
                    {selectedPackets?.length > 0 &&
                        canViewNewEnrollmentFlow && (
                            <ContinueButton
                                onClick={() => onClickHandler?.()}
                                btnText={btnText}
                            />
                        )}
                </div>
            )}
        </>
    )
}
