import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { onlineIcon } from '../../assets'
import './alert.scss'

interface AlertProps {
    type?: 'default' | 'floating'
    status?: 'success' | 'error'
    children?: React.ReactNode | string
    icon?: boolean
    iconType?: JSX.Element
    iconText?: string
    prefixText?: string
    className?: string
}

export const Alert: FC<AlertProps> = ({
    type = 'default',
    status = 'success',
    icon,
    iconType,
    iconText,
    children,
    prefixText,
    className,
    ...props
}) => {
    const { t } = useTranslation()
    const alertConfig = {
        error: {
            icon: iconType,
            status: 'Error',
            iconText
        },
        success: {
            icon: onlineIcon,
            status: 'Success',
            iconText: t('online')
        }
    }

    const alertType = (type: 'default' | 'floating') => {
        if (type === 'floating') {
            return (
                <div
                    className={[
                        'alert-floating',
                        `alert-floating--${status}`
                    ].join('  ')}
                    data-testid="alert-popup"
                >
                    {icon && <div>{alertConfig[status].icon}</div>}
                    <div className="">
                        <p className="alert-content">{children}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="alert-default" data-testid="alert-popup">
                    <p
                        className={['prefix-text', `prefix-text--${status}`]
                            .join(' ')
                            .trim()}
                    >
                        {prefixText}
                    </p>
                    <span className="alert-icon-parent">
                        {alertConfig[status].icon}
                        <p
                            className={[
                                'alert-icon-status',
                                `alert-icon-status--${status}`
                            ].join(' ')}
                        >
                            {alertConfig[status].iconText}
                        </p>
                    </span>
                    <p className={`alert-content ${className}`}>{children}</p>
                </div>
            )
        }
    }

    return (
        <div
            className={[
                'alert-parent',
                `alert-parent--${type}`,
                `alert--${status}`
            ].join(' ')}
            {...props}
            data-testid="alert-component"
        >
            {alertType(type)}
        </div>
    )
}

Alert.defaultProps = {
    type: 'default',
    status: 'success'
}
