import {
    VeSearchActionEnum,
    VeSearchActionType,
    VeSearchStateType
} from 'storeTypes'
import { VeType } from 'storeTypes/vesTypes'

export const veSearchReducer = (
    state: VeSearchStateType = { results: [] },
    action: VeSearchActionType
): { results: VeType[] } => {
    switch (action.type) {
        case VeSearchActionEnum.SAVE_VE_SEARCH_RESULTS:
            return {
                ...state,
                results: action.payload as VeType[]
            }
        default:
            return state
    }
}
