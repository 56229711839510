import React from 'react'

type CardButtonType = {
    name: string
    onClick: () => void
    icon: JSX.Element
}
type CardButtonProps = {
    cardButtons: CardButtonType[]
}
export const ProfileCardButton = ({ cardButtons }: CardButtonProps) => (
    <div className="profile-user-info">
        <div className="profile-buttons-container">
            {cardButtons.map(({ onClick, name, icon }, id) => (
                <button
                    key={id}
                    className="profile-button"
                    onClick={onClick}
                    aria-label={name}
                >
                    {icon}
                    <p>{name}</p>
                </button>
            ))}
        </div>
    </div>
)
