import { configureStore } from '@reduxjs/toolkit'
import {
    authReducer,
    navbarReducer,
    homeReducer,
    villagePreviousClientReducer,
    enrollmentReducer,
    enrollmentSubmissionReducer,
    villageClientReducer,
    veRankingReducer,
    searchClientListIndexReducer,
    openVillagePageReducer,
    veDepositReducer,
    userPermissionsReducer,
    clientDetailEnrollmentReducer,
    clientSearchReducer,
    cardPaymentReducer,
    cardPaymentResultReducer,
    singleClientReducer,
    villageSelectionInfoReducer,
    updatePackagesReducer,
    villageClientOrdersReducer,
    clientOrdersReducer,
    targetListReducer,
    interactionsReducer,
    paymentNotificationReducer,
    setIsVisitedReducer,
    setShowWarningReducer,
    enrollmentLocalStateReducer,
    displayFarmerIdModalReducer,
    multipleVillagesCountReducer,
    transactionsReducer,
    notificationReducer,
    veClientListReducer,
    veInteractionsReducer,
    fcMetricsReducer,
    vesReducer,
    cardInventoryReducer,
    fetchVeReducer,
    fetchVeDepositReducer,
    veSearchReducer
} from '../reducers'

export const configStore = () => {
    return configureStore({
        reducer: {
            auth: authReducer,
            navbar: navbarReducer,
            home: homeReducer,
            villagePreviousClientList: villagePreviousClientReducer,
            enrollment: enrollmentReducer,
            enrollmentSubmission: enrollmentSubmissionReducer,
            villageClientList: villageClientReducer,
            veClientList: veClientListReducer,
            veRankingList: veRankingReducer,
            searchClientList: searchClientListIndexReducer,
            openVillagePage: openVillagePageReducer,
            veDeposit: veDepositReducer,
            userPermissions: userPermissionsReducer,
            clientDetailEnrollment: clientDetailEnrollmentReducer,
            clientSearch: clientSearchReducer,
            cardPayment: cardPaymentReducer,
            cardPaymentResult: cardPaymentResultReducer,
            singleClient: singleClientReducer,
            villageSelectionInfo: villageSelectionInfoReducer,
            updatePackages: updatePackagesReducer,
            villageClientOrders: villageClientOrdersReducer,
            clientOrders: clientOrdersReducer,
            targetList: targetListReducer,
            interactions: interactionsReducer,
            veInteractions: veInteractionsReducer,
            paymentNotification: paymentNotificationReducer,
            isVisited: setIsVisitedReducer,
            showWarning: setShowWarningReducer,
            enrollmentLocalState: enrollmentLocalStateReducer,
            displayFarmerIdModal: displayFarmerIdModalReducer,
            multipleVillagesCount: multipleVillagesCountReducer,
            transactions: transactionsReducer,
            notifications: notificationReducer,
            fcMetrics: fcMetricsReducer,
            ves: vesReducer,
            cardInventoryState: cardInventoryReducer,
            ve: fetchVeReducer,
            storedVeDeposit: fetchVeDepositReducer,
            veSearch: veSearchReducer
        }
    })
}

const store = configStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
