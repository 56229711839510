import connectApi from '../api/connectApi'
import { constants, fetchProductPictures, getConnectUser } from 'helpers'
import { sendMessageToWorker } from '../helpers/communication'
import {
    HomeInterface,
    HomeActionType,
    VeHomeEnum,
    DepositActionEnum
} from '../storeTypes'
import { Dispatch } from 'react'
import { ErrorResponse } from '../types'

const { VES, VE_DEPOSIT, COUNTRY_CONFIG, ENROLLMENT } = constants.endpoints

const homeActionQueries = async (
    dispatch: Dispatch<HomeActionType>,
    veCode: string,
    country: string
) => {
    let appQueries = []
    let pageQueries = []

    // ve data api query
    const veDataQuery = connectApi.get(VES, {
        params: {
            code: veCode
        }
    })

    // ve deposit api query
    const veDepositQuery = connectApi.get(`${VE_DEPOSIT}${veCode}`)

    // country config api query
    const countryConfigQuery = connectApi.get(COUNTRY_CONFIG, {
        params: {
            country
        }
    })

    appQueries = [veDataQuery, veDepositQuery, countryConfigQuery]

    type PromiseSettledResult = {
        status: 'fulfilled'
        // eslint-disable-next-line
        value: any
    }

    const [veData, veDeposit, countryConfig] = (await Promise.allSettled(
        appQueries
    )) as PromiseSettledResult[]
    if (veDeposit.value?.data) {
        dispatch({
            type: DepositActionEnum.FETCH_VE_DEPOSIT_DATA,
            payload: veDeposit.value.data,
            veCode
        })
    }

    const { data: veDataResponse } = veData.value
    const veDataResult = veDataResponse[0]

    const rankingQuery = connectApi.get(VES, {
        params: {
            zone_code: veDataResult.zone_code
        }
    })

    const enrollmentPackagesQuery = connectApi.get(ENROLLMENT, {
        params: {
            zone_code: veDataResult.zone_code
        }
    })

    pageQueries = [rankingQuery, enrollmentPackagesQuery]
    // loader for ranking page
    dispatch({ type: VeHomeEnum.PRE_FETCHING_VE_RANKING_DATA })
    // loader for enrollment page
    dispatch({ type: VeHomeEnum.PRE_FETCHING_ENROLLMENT_FORM })

    Promise.allSettled(pageQueries).then(
        ([rankingData, enrollmentPackagesData]) => {
            if ('value' in rankingData) {
                // dispatch ranking response
                if (rankingData?.value?.data) {
                    dispatch({
                        type: VeHomeEnum.FETCH_VE_RANKING_DATA,
                        payload: rankingData.value.data
                    })
                }
            }
            if ('value' in enrollmentPackagesData) {
                if (enrollmentPackagesData?.value?.data) {
                    // dispatch enrollment package response
                    dispatch({
                        type: VeHomeEnum.FETCH_ENROLLMENT_FORM,
                        payload: enrollmentPackagesData.value.data
                    })
                    fetchProductPictures(
                        enrollmentPackagesData.value.data[0]?.product_list.map(
                            (product: { image_url: string }) =>
                                product.image_url
                        )
                    )
                }
            }
        }
    )

    return {
        veDataResult: veDataResult || {},
        countryConfig: countryConfig.value,
        veDeposit: veDeposit.value || { data: {} }
    }
}

export const veAction =
    ({ veCode, country }: HomeInterface) =>
    async (dispatch: Dispatch<HomeActionType>) => {
        dispatch({ type: VeHomeEnum.FETCHING_VE_HOME_DATA })
        try {
            const { countryConfig, veDataResult, veDeposit } =
                await homeActionQueries(dispatch, veCode, country)

            const connectUserLocalStorage = getConnectUser()
            const user = {
                ...connectUserLocalStorage,
                veVillages: veDataResult.ve_villages,
                supervisor_id: veDataResult.supervisor,
                zoneSFID: veDataResult.zone,
                zoneName: veDataResult.zone_name,
                zone_code: veDataResult.zone_code
            }
            // Update user info in service worker
            sendMessageToWorker({ 'user info': user })

            localStorage.setItem(constants.CONNECT_USER, JSON.stringify(user))
            localStorage.setItem(
                'country_config',
                JSON.stringify(countryConfig.data)
            )

            dispatch({
                type: VeHomeEnum.FETCH_VE_HOME_DATA,
                payload: { ...veDataResult, ...veDeposit.data }
            })
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: VeHomeEnum.FAIL_VE_HOME_REQUEST,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: VeHomeEnum.FAIL_VE_HOME_REQUEST,
                    payload: 'Un problème est survenu'
                })
            }
        }
    }
