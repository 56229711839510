import { groupBy } from 'lodash'
import { getClientTransactionDetails } from 'pages/payment/helpers/getClientTransactionData'

import {
    ClientDetailsPayloadInterface,
    TransactionKind,
    TransactionPayloadType
} from 'storeTypes'

const setVoucherMatchingClientCode = (
    transactionsByPaymentType: Record<string, TransactionPayloadType[]>
) => {
    const paymentModificationTransactions =
        transactionsByPaymentType[TransactionKind.PAYMENT_MODIFICATION] || []
    const paymentReversalTransactions =
        transactionsByPaymentType[TransactionKind.VOUCHER_PAYMENT_REVERSAL] ||
        []

    paymentModificationTransactions.forEach(
        (transaction: TransactionPayloadType) => {
            const transactionMatch = paymentReversalTransactions.find(
                (item) =>
                    item.voucher_code?.split('-R')[0] ===
                    transaction.voucher_code?.split('-')[0]
            )
            const isNotClientCodeSame =
                transaction.client_code !== transactionMatch?.client_code

            if (transactionMatch && isNotClientCodeSame) {
                transaction.matchingClientCode = transactionMatch?.client_code
                transactionMatch.matchingClientCode = transaction.client_code
            } else {
                transaction.matchingClientCode = ''
            }

            transaction.voucher_code = transaction?.voucher_code?.split(
                `-${transaction.client_code}`
            )[0]
        }
    )
}

const setClientTransactionFields = (
    client: ClientDetailsPayloadInterface,
    transactions: TransactionPayloadType[]
) => {
    const { clientBalance, transactionList, lastPaymentDate } =
        getClientTransactionDetails(client, transactions)

    client.smss = transactionList
    client.transactions = transactionList
    client.calculated_balance = clientBalance
    client.last_payment_date = lastPaymentDate
}

export const mergeClientsWithTransactions = (
    clients: ClientDetailsPayloadInterface[],
    transactions: TransactionPayloadType[]
) => {
    const transactionsByClientCode = groupBy(transactions, 'client_code')
    const transactionsByPaymentType = groupBy(transactions, 'type')

    setVoucherMatchingClientCode(transactionsByPaymentType)

    clients.forEach((client: ClientDetailsPayloadInterface) => {
        setClientTransactionFields(
            client,
            transactionsByClientCode[client.client_code] || []
        )
    })

    return clients
}
