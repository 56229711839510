import { CheckIcon, DownloadSpinner } from 'assets'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { DownloadStatus } from 'types'

type AppDownloadStatusProps = {
    status: DownloadStatus
    checkColor?: string
    backgroundColor?: string
}

export const AppDownloadStatus = ({
    status,
    checkColor = colors.primary,
    backgroundColor = colors.limeGreen
}: AppDownloadStatusProps) => {
    const { t } = useTranslation()
    if (status === DownloadStatus.DOWNLOADED) {
        return (
            <div
                className="downloadable-card--status"
                style={{ backgroundColor }}
            >
                <div className="downloadable-card--status--icon">
                    <CheckIcon color={checkColor} />
                </div>
                <div className="downloadable-card--status--text">
                    {t('village.availableOffline')}
                </div>
            </div>
        )
    } else {
        return (
            <div className="downloadable-card--status">
                <div className="downloadable-card--status--icon">
                    {status === DownloadStatus.NOT_DOWNLOADED ? (
                        <CheckIcon color={colors.grey} />
                    ) : (
                        <DownloadSpinner />
                    )}
                </div>
                <div className="downloadable-card--status--text">
                    {t('village.notAvailableOffline')}
                </div>
            </div>
        )
    }
}
