import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './menu.scss'

interface MenuProps {
    children: JSX.Element
    type: 'mobile menu' | 'top menu'
    optionsLen: number
}

type ActiveState = { status: boolean; key: number; prevKey?: number }
type DisplayOptions = { key: string }

export const Menu = ({ children, type, optionsLen, ...props }: MenuProps) => {
    const [active, setActive] = useState<ActiveState>({
        status: true,
        key: 0,
        prevKey: 0
    })
    const [displayOpt, setDisplayOpt] = useState<DisplayOptions>({ key: '' })
    const menuChildren: JSX.Element[] = children.props.children
    const isDesktop = type === 'top menu'
    const desktopMenu = isDesktop && 'desktop-menu-parent'
    const isMobileLength = optionsLen <= 2 && !isDesktop
    // render menu children
    const mobileChildren = menuChildren.map(
        (child: JSX.Element, idx: number) => {
            return React.cloneElement(child, {
                setActive,
                idx,
                key: idx,
                path: child.props.path,
                active,
                isDesktop,
                displayOpt,
                setDisplayOpt
            })
        }
    )
    // reset menu to home menu
    const resetHomeMenu = (): void => {
        const menuConfig = { status: true, key: 0 }
        setActive(menuConfig)
        sessionStorage.setItem('activeMenu', JSON.stringify(menuConfig))
    }
    const desktopChildren: JSX.Element = (
        <>
            <div>
                <Link
                    to="/"
                    className="menu-logo-link"
                    onClick={resetHomeMenu}
                    id="logo-menu"
                >
                    <img src="/images/logo.png" className="menu-logo" />
                </Link>
            </div>
            <div className="desktop-menu-children">{mobileChildren}</div>
        </>
    )

    return (
        <div
            className={[
                `menu-parent${isMobileLength ? '--arrange' : ''}`,
                desktopMenu
            ]
                .join(' ')
                .trim()}
            {...props}
        >
            {isDesktop ? desktopChildren : mobileChildren}
        </div>
    )
}

Menu.defaultProps = {
    type: 'mobile menu'
}
