import { setTimeByMinute } from '../helpers/timeHandler'

export const hasLocalDataExpired = (storeName: string, key: string) => {
    const expirationStore = JSON.parse(localStorage.getItem(storeName) || '{}')
    const expirationTime = parseFloat(expirationStore[key]) || 0
    const currentTime = new Date().getTime()

    return currentTime > expirationTime
}

export const updateLocalDataExpirationTime = (
    storeName: string,
    key: string,
    duration = 1
) => {
    const previousExpirationStore = JSON.parse(
        localStorage.getItem(storeName) || '{}'
    )

    localStorage.setItem(
        storeName,
        JSON.stringify({
            ...previousExpirationStore,
            [key]: setTimeByMinute(duration)
        })
    )
}
