import { PackageIcon, TruckDeliveryIcon } from 'assets'
import { ClientGoalItem } from 'components/client/ClientGoalItem'
import React from 'react'
import { colors } from 'styles/colors'

type GoalItemType = {
    delivered: boolean
    imageUrl: string
    nbOfGoalItems: number
    price: number
    productId: string
    productName: string
    scheduled_delivery_date?: string
}

export const ClientOrder = ({
    goalItems,
    finisher,
    canUpdatePackage,
    modifyHandler,
    t
}: {
    goalItems: GoalItemType[]
    finisher: boolean
    canUpdatePackage: boolean
    modifyHandler: () => void
    t: (key: string, values?: { [key: string]: string | number }) => string
}) => {
    const className =
        'client-goal-items-banner' +
        (goalItems[0]?.delivered ? '--delivered' : finisher ? '--finisher' : '')

    return (
        <div aria-label="goal-items-section">
            <div className={className}>
                {goalItems[0]?.delivered
                    ? PackageIcon({ color: colors.lightGreen })
                    : TruckDeliveryIcon({
                          color: finisher ? colors.purple : colors.grey
                      })}
                <p className="scheduled-delivery-date">
                    {goalItems[0]?.scheduled_delivery_date &&
                        t('fullDateMonthYear', {
                            date: new Date(
                                goalItems[0]?.scheduled_delivery_date
                            ) as unknown as string
                        })}
                </p>
            </div>
            <div className="goal-items-grid">
                {goalItems.map((goalItem, index) => (
                    <div
                        key={index}
                        className={`goal-item${
                            index === goalItems.length - 1 &&
                            goalItems.length % 2 !== 0
                                ? '-center'
                                : ''
                        }`}
                        onClick={
                            canUpdatePackage && !goalItem.delivered
                                ? modifyHandler
                                : () => {}
                        }
                    >
                        <ClientGoalItem
                            goalItem={goalItem}
                            color={
                                goalItem.delivered
                                    ? colors.lightGreen
                                    : finisher
                                    ? colors.purple
                                    : colors.primary
                            }
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
