import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { abbreviateName, constants } from '../../helpers'
import { LastPaymentDate } from './LastPaymentDate'
import { LastYearDelivered } from './LastYearDelivered'
import { CardClientContext } from '../../contexts'
import { RemainingPayment } from './RemainingPayment'
import { useScreenSize } from '../../hooks'

export const CardNameAbr = ({
    children,
    size,
    subHeader,
    performanceColoring,
    balance,
    hideBalance,
    clientType,
    ...props
}) => {
    const nameAbbr = abbreviateName(children)
    const {
        formattedLastPaymentDate,
        clientStatus,
        lastYearDelivered,
        paymentBalance,
        paymentCompleted
    } = useContext(CardClientContext)
    const screenSize = useScreenSize()
    const { TABLET_SIZE } = constants
    const isTablet = screenSize.screenWidth === TABLET_SIZE
    const colorClass = performanceColoring
        ? `card-client-abbr--${performanceColoring}`
        : ''
    const [showPaymentInfo] = useState(
        !paymentCompleted && !isTablet && !clientStatus && !hideBalance
    )
    const subHeaderExtend = () => {
        if (clientStatus) {
            return ''
        }
        if (lastYearDelivered) {
            return (
                <LastYearDelivered
                    className="display-mobile"
                    paymentBalance={paymentBalance}
                >
                    {lastYearDelivered?.toString().trim()}
                </LastYearDelivered>
            )
        }
        return (
            <>
                <LastPaymentDate
                    className="display-mobile"
                    dateDisplayLength="short"
                    langTranslate
                >
                    {formattedLastPaymentDate}
                </LastPaymentDate>
            </>
        )
    }

    return (
        <div {...props}>
            <div className="card-client-name-abbr">
                <div
                    className={[
                        'card-client-abbr',
                        `card-client-abbr-payment--${size}`,
                        colorClass
                    ].join(' ')}
                >
                    {nameAbbr}
                </div>
                <div className="card-client-data">
                    <h2 className="card-client-name">{children}</h2>
                    <div className="card-client-footer">
                        <p className="card-client-id card-client-id--payment">
                            {subHeader}
                        </p>
                        {subHeaderExtend()}
                        {showPaymentInfo && (
                            <RemainingPayment
                                text={
                                    clientType ===
                                    constants.CLIENT_TYPES.PREVIOUS_CLIENT
                                        ? 'village.available'
                                        : 'village.leftToPay'
                                }
                            >
                                {balance}
                            </RemainingPayment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

CardNameAbr.propTypes = {
    children: PropTypes.string,
    subHeader: PropTypes.string,
    balance: PropTypes.string,
    hideBalance: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    performanceColoring: PropTypes.string,
    clientCertified: PropTypes.bool,
    clientType: PropTypes.oneOf([
        constants.CLIENT_TYPES.PREVIOUS_CLIENT,
        constants.CLIENT_TYPES.CURRENT_CLIENT
    ])
}

CardNameAbr.defaultProps = {
    size: 'small'
}
