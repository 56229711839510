import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import './topBar.scss'
import { backIcon, closeIcon } from '../../assets'
import { TopBarInterface } from './topBarTypes'

export const TopBar: React.FC<TopBarInterface> = ({
    children,
    back,
    close,
    backNavigation,
    className = '',
    iconColor,
    transparentTopBar,
    ...props
}) => {
    const navigate = useNavigate()
    const topBarElm = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // get the height of parent element
        const scrollTop =
            topBarElm.current?.offsetParent instanceof HTMLElement
                ? topBarElm.current?.offsetParent?.offsetTop
                : 0
        const headerElm = document.getElementById('header-bar')
        const scrollHandler = () => {
            if (scrollY >= scrollTop) {
                return headerElm?.classList.add('header-bar-shadow')
            }
            return headerElm?.classList.remove('header-bar-shadow')
        }
        if (headerElm) {
            window.addEventListener('scroll', scrollHandler)
        }

        return () => window.removeEventListener('scroll', scrollHandler)
    }, [])

    return (
        <div
            className={`topbar ${className} ${
                transparentTopBar ? 'topbar--transparent' : ''
            }`.trim()}
            {...props}
            ref={topBarElm}
        >
            {back && (
                <span
                    className="topbar-icon topbar-icon--back"
                    onClick={() => {
                        if (backNavigation) {
                            backNavigation()
                        } else {
                            navigate(-1)
                        }
                    }}
                    data-testid="top-bar-back-arrow"
                    id="topbar-back-btn"
                    aria-label="top-bar-back-button"
                >
                    {backIcon({ color: iconColor || '' })}
                </span>
            )}
            <span
                className={back ? 'topbar-name topbar-header' : 'topbar-name'}
            >
                {children}
            </span>
            {close && (
                <span
                    className="topbar-icon topbar-icon--close"
                    onClick={close}
                    data-testid="top-bar-close-icon"
                    id="topbar-close-btn"
                    aria-label="top-bar-close-button"
                >
                    {closeIcon({ color: iconColor || '' })}
                </span>
            )}
        </div>
    )
}

TopBar.propTypes = {
    children: PropTypes.any,
    back: PropTypes.bool,
    close: PropTypes.func,
    backNavigation: PropTypes.func
}
