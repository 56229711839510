import {
    CashHandIcon,
    FootIcon,
    MyAgroLogo,
    NotificationIcon,
    RightArrowIcon
} from 'assets'
import { constants } from 'helpers'
import React, { FC } from 'react'
import { ClientType, NotificationType } from 'storeTypes'

type FullNotificationModalType = {
    notificationData: NotificationType | null
}

export const FullNotificationModal: FC<FullNotificationModalType> = ({
    notificationData
}) => {
    const isPaymentReminder =
        notificationData?.type.toUpperCase() ===
            constants.NOTIFICATIONS.TYPES.REMINDER &&
        notificationData?.sub_type.toUpperCase() ===
            constants.NOTIFICATIONS.SUB_TYPES.PAYMENT
    return (
        <div className="modal-notification-container">
            <div className="top-close-bg"></div>
            <div className="status-icon">
                {isPaymentReminder ? (
                    <FootIcon />
                ) : (
                    <NotificationIcon width="37px" height="42px" />
                )}
            </div>
            {!isPaymentReminder && (
                <div className="status-notification">
                    <CashHandIcon />
                    <RightArrowIcon />
                    <MyAgroLogo size={'60px'} />
                </div>
            )}
            <div className="modal-content-parent">
                <p
                    className="modal-content"
                    aria-label="notification-modal-content"
                >
                    {notificationData?.message_fr}
                </p>
            </div>
            {notificationData?.client_list &&
                notificationData?.client_list.length > 0 && (
                    <div className="modal-client-list">
                        {notificationData.client_list.map(
                            (client: Partial<ClientType>) => (
                                <div
                                    key={client.client_code}
                                    className="modal-client-container"
                                >
                                    <p
                                        className="modal-client-id"
                                        aria-label="client-id"
                                    >
                                        ID:{client.client_code}
                                    </p>
                                    <p
                                        className="modal-client-name"
                                        aria-label="client-name"
                                    >
                                        {client.fullname}
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                )}
        </div>
    )
}
