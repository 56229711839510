import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Button, Col, Container, Row, TargetList } from 'components'
import './profile.scss'
import { FarmerIcon, filledPhone, searchIcon, VeIcon } from 'assets'
import { colors } from 'styles/colors'
import { useBindDispatch } from 'hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'store/configStore'
import {
    amountFormatter,
    constants,
    searchHandler,
    sortTargetList
} from 'helpers'
import { compact } from 'lodash'
import {
    ClientDetailsPayloadInterface,
    InteractionPayloadType,
    TargetPayloadType
} from 'storeTypes'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export const VillageProfile = () => {
    const [searchValue, setSearchValue] = useState('')
    const { t } = useTranslation()
    const ref = useRef(null)
    const { veCode = '' } = useParams()

    const {
        fetchVeClientListHandler,
        filterTargetListHandler,
        getInteractionsHandler,
        fetchTargetListHandler,
        processTargetListHandler,
        getVeHandler,
        resetVeHandler
    } = useBindDispatch()

    const { ve } = useSelector(({ ve }: RootState) => ve)

    const {
        targetList,
        processedTargetList,
        selectedFilter: storeSelectedFilter
    } = useSelector(
        ({ targetList }: RootState) =>
            targetList[veCode] || {
                targetList: []
            }
    )
    const { interactions = [] } = useSelector(
        ({ veInteractions }: RootState) => veInteractions
    )
    const { clientList, prevClientList } = useSelector(
        ({ veClientList }: RootState) => veClientList
    )
    const navigate = useNavigate()

    const [searchResults, setSearchResults] = useState(processedTargetList)

    const clientSearchHandler = () => {
        if (searchValue.trim().length > 2) {
            const sing = searchHandler(processedTargetList || [], {
                fullname: searchValue,
                reversed_fullname: searchValue,
                client_code: searchValue
            })
            return setSearchResults(sing)
        }
        setSearchResults(processedTargetList)
    }

    useEffect(() => {
        clientSearchHandler()
    }, [searchValue, processedTargetList])

    useEffect(() => {
        getVeHandler(veCode)
        if (!processedTargetList?.length) {
            fetchTargetListHandler({ veCode })
        }
        localStorage.removeItem(constants.CLIENT_DETAIL_LOCAL_STORE)

        return () => {
            resetVeHandler()
        }
    }, [])

    useEffect(() => {
        if (
            (clientList.length > 0 || prevClientList.length > 0) &&
            targetList.length > 0
        ) {
            const clients = [
                ...Object.values(clientList),
                ...Object.values(prevClientList)
            ].flat() as unknown as ClientDetailsPayloadInterface[]

            const clientCodes = compact(
                targetList.map(
                    (target: TargetPayloadType) => target.client_code
                )
            )

            processTargetListHandler({
                targetList,
                clients,
                interactions: (interactions as InteractionPayloadType[]).filter(
                    (interaction) =>
                        clientCodes.includes(interaction.client_code) &&
                        interaction.survey_version ===
                            constants.TARGET_LIST_SURVEY_VERSION
                ),
                selectedFilter: storeSelectedFilter,
                veCode
            })
        }
    }, [interactions, targetList, clientList, prevClientList])

    useEffect(() => {
        if (targetList?.length > 0) {
            const clientCodes = compact(
                targetList.map(
                    (target: TargetPayloadType) => target.client_code
                )
            )
            getInteractionsHandler({
                clientCodes,
                surveyName: constants.TARGET_LIST_SURVEY_NAME,
                surveyVersion: constants.TARGET_LIST_SURVEY_VERSION
            })
            if (ve?.ve_villages) {
                fetchVeClientListHandler(ve.ve_villages)
            }
        }
    }, [targetList, ve])

    const onSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const { value } = e.target
        setSearchValue(value)
    }

    const sortedTargetList = useMemo(
        () => sortTargetList(searchResults || []),
        [searchResults]
    )

    const profileDetailHandler = () =>
        navigate(`/fc/village/ve/${veCode}/profile`)
    return (
        <div className="page-wrapper">
            <Container>
                <Row>
                    <Col md={12}>
                        <div>
                            <h2 className="ve-profile--header">
                                {ve?.current_villages}
                            </h2>
                        </div>
                        <div
                            className="ve-profile--detail"
                            onClick={profileDetailHandler}
                            aria-label="ve-profile-detail"
                        >
                            <VeIcon
                                color={colors.blue}
                                width="45"
                                height="49"
                            />
                            <h2 className="ve-profile--name">{ve?.name}</h2>
                            <div className="ve-profile--contact">
                                {filledPhone({ color: colors.blue })}
                                <p className="ve-profile--phone">
                                    {amountFormatter(ve?.phone_flotte || 0, '')}
                                </p>
                            </div>
                        </div>
                        <div>
                            <Button
                                style="primary"
                                className="ve-profile--button"
                            >
                                <FarmerIcon color={colors.white} />
                                <span className="ve-profile--button-text">
                                    {t('fcProfile.farmers')}
                                </span>
                            </Button>
                        </div>
                        <div className="ve-profile--target-list-container">
                            <TargetList
                                onSearchInput={onSearchInput}
                                searchValue={searchValue}
                                filterTargetListHandler={
                                    filterTargetListHandler
                                }
                                veCode={veCode}
                                sortedTargetList={sortedTargetList}
                                storeSelectedFilter={storeSelectedFilter || ''}
                                targetScrollRef={ref}
                                t={t}
                                searchIcon={searchIcon({
                                    size: '24',
                                    color: colors.lightGrey
                                })}
                                searchIconPos="left"
                                searchPlaceholder={t(
                                    'fcProfile.searchPlaceholderText'
                                )}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
