import React, { FC } from 'react'
import { ReferralOptionsProps } from 'types/clientReferrals'
import { ReferralItem } from './ReferralItem'

export const ReferralOptions: FC<ReferralOptionsProps> = ({
    referralsOptions,
    selectedReferrerOption,
    setSelectedReferrerOption
}) => (
    <div className="referrals">
        {referralsOptions.map((option, index) => (
            <ReferralItem
                key={index}
                referrerOption={{ option, key: index }}
                isOptionSelected={selectedReferrerOption?.key === index}
                shouldToggleClickButton={
                    !selectedReferrerOption ||
                    selectedReferrerOption?.key !== index
                }
                setSelectedReferrerOption={setSelectedReferrerOption}
            />
        ))}
    </div>
)
