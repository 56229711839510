import React from 'react'
import { Card } from 'components/card/Card'
import { DownloadStatus } from 'types'
import { colors } from 'styles/colors'

import './card.scss'
import { AppDownloadStatus } from 'components/appDownloadStatus/AppDownloadStatus'

type DownloadableCardProps = {
    headerColor: string
    borderBottomColor: string
    icon: JSX.Element
    endIcon?: JSX.Element
    title: string
    subtitles: string[]
    clickHandler?: () => void
    status?: DownloadStatus
    downloadCheckColor?: string
    downloadBackgroundColor?: string
}

export const DownloadableCard = ({
    headerColor,
    borderBottomColor,
    icon,
    endIcon,
    title,
    subtitles = [],
    clickHandler,
    status = DownloadStatus.NOT_DOWNLOADED,
    downloadCheckColor = colors.primary,
    downloadBackgroundColor = colors.limeGreen
}: DownloadableCardProps) => {
    return (
        <Card
            className="downloadable-card"
            aria-label="downloadable-card"
            style={{ borderBottom: `1px solid ${borderBottomColor}` }}
            onClick={clickHandler}
        >
            <div
                className="downloadable-card--header"
                style={{
                    backgroundColor: headerColor
                }}
            >
                <AppDownloadStatus
                    status={status}
                    checkColor={downloadCheckColor}
                    backgroundColor={downloadBackgroundColor}
                />
            </div>
            <div className="downloadable-card--overview">
                <div className="downloadable-card--overview--icon">{icon}</div>
                <div className="downloadable-card--overview--info">
                    <h2 className="downloadable-card--overview--title">
                        {title}
                    </h2>
                    {subtitles.map((subtitle) => (
                        <div
                            key={subtitle}
                            className="downloadable-card--overview--subtitle"
                        >
                            {subtitle}
                        </div>
                    ))}
                </div>
                <div className="downloadable-card--overview--end-icon">
                    {endIcon}
                </div>
            </div>
        </Card>
    )
}
