import React from 'react'
import { VillageType } from 'helpers'
import { useDownloadVillage } from 'hooks'
import { Alert, DownloadableCard } from 'components'
import { NetworkBarSvgs } from 'assets'
import { colors } from 'styles/colors'
import { DownloadStatus } from 'types'

export type DownloadVillageCardProps = {
    village: VillageType
    openClickHandler: (village: VillageType) => void
    downloadErrorMsg?: string
}

export const DownloadVillageCard = ({
    village,
    openClickHandler,
    downloadErrorMsg
}: DownloadVillageCardProps) => {
    const { downloadStatus, fetchVillageData, isDownloadError } =
        useDownloadVillage([village.sf_id])
    const { VillagesIcon } = NetworkBarSvgs
    const clickHandler = () => {
        if (downloadStatus === DownloadStatus.NOT_DOWNLOADED) {
            fetchVillageData()
        } else if (downloadStatus === DownloadStatus.DOWNLOADED) {
            openClickHandler(village)
        }
    }

    return (
        <>
            <DownloadableCard
                key={village.code}
                headerColor={colors.lighterGreen}
                borderBottomColor={colors.primary}
                icon={
                    <VillagesIcon
                        width="35"
                        height="35"
                        color={colors.primary}
                    />
                }
                title={village.name || ''}
                subtitles={(
                    (village as VillageType).ves?.map((ve) => ve.name || '') ||
                    []
                ).concat([village.code || ''])}
                status={downloadStatus}
                clickHandler={() => clickHandler()}
            />

            {isDownloadError && (
                <Alert type="floating" status={'error'}>
                    {downloadErrorMsg}
                </Alert>
            )}
        </>
    )
}
