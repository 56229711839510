import React from 'react'
import { Container, Row, Col, Card } from 'components'
import { PaymentHistory } from '../PaymentHistory'
import { TransactionPayloadType } from 'storeTypes'
import { PaymentSummaryType } from '../types'
import { PaymentProgress } from '../PaymentProgress'
import { useTranslation } from 'react-i18next'

type PaymentHistoryContentProps = {
    paymentSummary: PaymentSummaryType
    isAnyDelivered?: boolean
    transactions: [string, TransactionPayloadType[]][]
    country: string
    animate: boolean
}

export const PaymentHistoryContent: React.FC<PaymentHistoryContentProps> = ({
    paymentSummary,
    isAnyDelivered,
    transactions,
    animate
}) => {
    const { t } = useTranslation()

    return (
        <div
            className="client-payment-parent page-wrapper"
            data-testid="payment-history-card"
        >
            <Container>
                <Row>
                    <Col md={12}>
                        <Card
                            className={
                                animate
                                    ? 'height-100 animate-show-1 animate-showed-1'
                                    : 'height-100 animate-show-1'
                            }
                        >
                            <div className="village-client-details-padding village-payment--main">
                                <h2 className="client-payment--header">
                                    {t('village.payments')}
                                </h2>
                                <PaymentProgress
                                    paymentSummary={paymentSummary}
                                    isAnyDelivered={isAnyDelivered}
                                />
                                <div className="list">
                                    <PaymentHistory
                                        transactions={transactions}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
