import { Dispatch } from 'react'

import connectApi from 'api/connectApi'
import {
    constants,
    internetConnect,
    sendMessageToWorker,
    sendMessageToWorkerWithResponse
} from 'helpers'
import {
    VeActionEnum,
    VeActionType,
    VesActionEnum,
    VesActionType
} from 'storeTypes/vesTypes'
import {
    hasLocalDataExpired,
    updateLocalDataExpirationTime
} from 'datastores/LocalDataExpiration'

export const fetchVesAction =
    (zoneCode: string) => async (dispatch: Dispatch<VesActionType>) => {
        try {
            dispatch({
                type: VesActionEnum.FETCHING_VES
            })

            if (!hasLocalDataExpired(constants.VES_EXPIRATION_NAME, zoneCode)) {
                const localVes = await sendMessageToWorkerWithResponse({
                    [constants.MESSAGES.GET_VES]: zoneCode
                })

                return dispatch({
                    type: VesActionEnum.FETCH_VES_SUCCESS,
                    payload: localVes
                })
            }

            if (internetConnect()) {
                const response = await connectApi.get(constants.endpoints.VES, {
                    params: {
                        zone_code: zoneCode
                    }
                })

                const remoteVes = response.data

                sendMessageToWorker({
                    [constants.MESSAGES.STORE_VES]: { ves: remoteVes, zoneCode }
                })

                updateLocalDataExpirationTime(
                    constants.VES_EXPIRATION_NAME,
                    zoneCode,
                    constants.VES_EXPIRATION_DURATION
                )

                return dispatch({
                    type: VesActionEnum.FETCH_VES_SUCCESS,
                    payload: remoteVes
                })
            }

            dispatch({
                type: VesActionEnum.FETCH_VES_FAILURE,
                payload: 'Un problème est survenu'
            })
        } catch (error: unknown) {
            dispatch({
                type: VesActionEnum.FETCH_VES_FAILURE,
                payload: 'Un problème est survenu'
            })
        }
    }

export const getVeAction =
    (veZoneCode: string) => async (dispatch: Dispatch<VeActionType>) => {
        const ve = await sendMessageToWorkerWithResponse({
            [constants.MESSAGES.GET_VE]: veZoneCode
        })

        dispatch({
            type: VeActionEnum.FETCH_VE_SUCCESS,
            payload: ve
        })
    }

export const resetVeAction = () => async (dispatch: Dispatch<VeActionType>) => {
    dispatch({
        type: VeActionEnum.FETCH_VE_SUCCESS,
        payload: null
    })
}
