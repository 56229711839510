import React from 'react'
import PropTypes from 'prop-types'
import './spinner.scss'

export const Spinner = ({
    size = '200',
    pageSpinner = false,
    fullscreen = false,
    loadingMessage = '',
    ...props
}) => {
    const pageSpin = pageSpinner ? 'page-spinner' : ''
    const coverFullScreen = fullscreen ? 'page-spin-fullscreen' : ''
    return (
        <div
            className={[pageSpin, coverFullScreen].join(' ').trim()}
            data-testid="spinner"
            aria-label="loading"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    margin: 'auto',
                    background: 'rgba(241, 242, 243, 0)',
                    display: 'block',
                    shapeRendering: 'auto'
                }}
                width="200px"
                height={`${size}px`}
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                {...props}
            >
                <circle cx="30" cy="50" fill="#16a34a" r="20">
                    <animate
                        attributeName="cx"
                        repeatCount="indefinite"
                        dur="0.7407407407407407s"
                        keyTimes="0;0.5;1"
                        values="30;70;30"
                        begin="-0.37037037037037035s"
                    ></animate>
                </circle>
                <circle cx="70" cy="50" fill="#d8f05b" r="20">
                    <animate
                        attributeName="cx"
                        repeatCount="indefinite"
                        dur="0.7407407407407407s"
                        keyTimes="0;0.5;1"
                        values="30;70;30"
                        begin="0s"
                    ></animate>
                </circle>
                <circle cx="30" cy="50" fill="#16a34a" r="20">
                    <animate
                        attributeName="cx"
                        repeatCount="indefinite"
                        dur="0.7407407407407407s"
                        keyTimes="0;0.5;1"
                        values="30;70;30"
                        begin="-0.37037037037037035s"
                    ></animate>
                    <animate
                        attributeName="fill-opacity"
                        values="0;0;1;1"
                        calcMode="discrete"
                        keyTimes="0;0.499;0.5;1"
                        dur="0.7407407407407407s"
                        repeatCount="indefinite"
                    ></animate>
                </circle>
            </svg>
            {loadingMessage && (
                <p className="partial-loader--text">{loadingMessage}</p>
            )}
        </div>
    )
}

Spinner.propTypes = {
    size: PropTypes.string,
    pageSpinner: PropTypes.bool,
    fullscreen: PropTypes.bool,
    loadingMessage: PropTypes.string
}
