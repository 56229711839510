import React from 'react'
import './checkbox.scss'
import { CheckBoxInterface } from './checkBoxTypes'

export const CheckBox: React.FC<CheckBoxInterface> = ({
    children,
    checked,
    name,
    onChange,
    ...props
}) => {
    return (
        <div {...props}>
            <label className="checkbox-parent">
                <input
                    type="checkbox"
                    checked={checked}
                    name={name}
                    onChange={onChange}
                    {...props}
                />
                <span className="checkbox-checkmark"></span>
                {children}
            </label>
        </div>
    )
}
