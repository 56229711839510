import React, { FC } from 'react'
import { Button } from './Button'
import { useTranslation } from 'react-i18next'
import { ButtonStylesEnum } from 'types/buttonStylesEnum'

export const ContinueButton: FC<{
    onClick: () => void
    btnText?: string
    className?: string
    style?: ButtonStylesEnum
    icon?: JSX.Element
}> = ({ onClick, btnText, className, style, icon }) => {
    const { t } = useTranslation()
    return (
        <div className={className}>
            <Button
                style={style || ButtonStylesEnum.PRIMARY}
                className="client-details--bottom-button"
                onClick={onClick}
                icon={icon}
            >
                {btnText || t('continue')}
            </Button>
        </div>
    )
}
