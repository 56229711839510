import { VeType } from './vesTypes'

export interface FcInfo {
    name: string
    email: string
    role: string | null
    code: string
    zone_code: string | null
    zone_name: string | null
    country: string
    is_active: boolean
    ve_villages: { code: string }[]
    phone_number: string | null
}

export type FcMetricsPayload = {
    fc_metrics: {
        fc_code: string
        total_monthly_sales: number
        total_enrolled_this_month: number
        total_active_ids_this_month: number
        total_potential_clients_visited: number
        total_new_clients_registered: number
        total_finishers: number
        total_enrollments_without_payments: number
        total_payments_with_no_enrollment: number
        total_ves: number
        total_villages: number
        total_farmers: number
        total_deposits: number
        total_sales: number
    }
    fc_info: FcInfo
}

export enum FcMetricsEnum {
    FETCHING_FC_METRICS = 'FETCHING_FC_METRICS',
    FETCH_FC_METRICS_SUCCESS = 'FETCH_FC_METRICS_SUCCESS',
    FETCH_FC_METRICS_FAILURE = 'FETCH_FC_METRICS_FAILURE'
}

export type FetchFcMetricsAction =
    | { type: FcMetricsEnum.FETCHING_FC_METRICS }
    | {
          type: FcMetricsEnum.FETCH_FC_METRICS_SUCCESS
          payload: FcMetricsPayload
      }
    | { type: FcMetricsEnum.FETCH_FC_METRICS_FAILURE; payload: string }

export enum VeSearchActionEnum {
    SAVE_VE_SEARCH_RESULTS = 'SAVE_VE_SEARCH_RESULTS'
}

export type VeSearchActionType = {
    type: VeSearchActionEnum.SAVE_VE_SEARCH_RESULTS
    payload?: VeType[]
}

export type VeSearchStateType = {
    results: VeType[]
}
