import { useCallback, useEffect, useMemo, useState } from 'react'
import { DownloadStatus } from 'types/downloadStatus'
import { useBindDispatch } from './useBindDispatch'
import { RootState } from '../store/configStore'
import { useAppSelector } from './useAppSelector'
import { constants, internetConnect } from '../helpers'

export type useDownloadVillageType = {
    fetchVillageData: () => Promise<void>
    downloadStatus: DownloadStatus
}

export const useDownloadVillage = (villageIds: string[] = []) => {
    const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>(
        DownloadStatus.NOT_DOWNLOADED
    )
    const [isDownloadError, setIsDownloadError] = useState(false)

    const {
        storeVillageOrdersHandler,
        storeVillageClientListHandler,
        transactionsHandler,
        fetchInteractionsHandler
    } = useBindDispatch()

    const clientsLoadingData = useAppSelector(
        ({ villageClientList }: RootState) => villageClientList
    )
    const ordersLoadingData = useAppSelector(
        ({ villageClientOrders }: RootState) => villageClientOrders
    )
    const transactionsLoadingData = useAppSelector(
        ({ transactions }: RootState) => transactions
    )
    const interactionsLoadingData = useAppSelector(
        ({ interactions }: RootState) => interactions
    )

    const fetchVillageData = useCallback(async () => {
        const internetConnection = internetConnect()

        if (!internetConnection) {
            setIsDownloadError(true)
            return
        }

        if (!villageIds.length) {
            return
        }
        if (downloadStatus === DownloadStatus.NOT_DOWNLOADED) {
            setDownloadStatus(DownloadStatus.DOWNLOADING)
        }

        const promises: unknown[] = []
        villageIds.forEach((villageId) => {
            const clientsPromise = storeVillageClientListHandler({
                villages: [{ sf_id: villageId }]
            })
            const ordersPromise = storeVillageOrdersHandler([
                { sf_id: villageId }
            ])
            const transactionsPromise = transactionsHandler({ villageId })
            const interactionsPromise = fetchInteractionsHandler({
                villageIds: [villageId],
                surveyNames: [
                    constants.FARMER_AWARENESS_SURVEY_NAME,
                    constants.TARGET_LIST_SURVEY_NAME
                ]
            })
            promises.push(
                clientsPromise,
                ordersPromise,
                transactionsPromise,
                interactionsPromise
            )
        })
        await Promise.all(promises)
    }, [downloadStatus, villageIds])

    const isLoaded = useMemo(() => {
        if (!villageIds.length) return true
        let hasError = false
        const allResults = villageIds.every((villageId) => {
            const villageResult = [
                clientsLoadingData[villageId],
                ordersLoadingData[villageId],
                transactionsLoadingData[villageId],
                interactionsLoadingData[villageId]
            ].every((state) => {
                if (state?.error) {
                    hasError = true
                }
                return state?.loading === false && !state?.error
            })
            return villageResult
        })
        if (hasError && downloadStatus === DownloadStatus.DOWNLOADING) {
            setDownloadStatus(DownloadStatus.NOT_DOWNLOADED)
        }
        return allResults
    }, [
        villageIds,
        clientsLoadingData,
        ordersLoadingData,
        transactionsLoadingData,
        interactionsLoadingData
    ])

    const updateDownloadStatus = useCallback(async () => {
        if (!villageIds.length) {
            setDownloadStatus(DownloadStatus.DOWNLOADED)
            return
        }
        const isPrevDownloaded = await Promise.all(
            villageIds.map(async (villageId) => {
                return (
                    Number(
                        await localStorage.getItem(
                            `village-${villageId}-download_status`
                        )
                    ) === DownloadStatus.DOWNLOADED
                )
            })
        ).then((results) => results.every((status) => status))
        if (isLoaded) {
            villageIds.forEach(async (villageId) => {
                await localStorage.setItem(
                    `village-${villageId}-download_status`,
                    JSON.stringify(DownloadStatus.DOWNLOADED)
                )
            })
        }
        if (isPrevDownloaded || isLoaded) {
            setDownloadStatus(DownloadStatus.DOWNLOADED)
        } else if (
            downloadStatus !== DownloadStatus.NOT_DOWNLOADED &&
            downloadStatus !== DownloadStatus.DOWNLOADING &&
            villageIds.length
        ) {
            setDownloadStatus(DownloadStatus.NOT_DOWNLOADED)
        }
    }, [isLoaded, villageIds])

    useEffect(() => {
        updateDownloadStatus()
    }, [isLoaded, villageIds])

    useEffect(() => {
        if (isDownloadError) {
            const timer = setTimeout(() => {
                setIsDownloadError(false)
            }, 2000)

            return () => clearTimeout(timer)
        }
    }, [isDownloadError])

    return {
        downloadStatus,
        fetchVillageData,
        isDownloadError
    }
}
