import React from 'react'
import { CircleMetric } from './CircleMetric'
import './circleMetrics.scss'
import { MetricType } from './circleMetricsTypes'

export const CircleMetrics: React.FC<{ metrics: MetricType[] }> = ({
    metrics
}) => {
    return (
        <div className="metrics--summaries" aria-label="circle-metrics">
            {metrics.map((metric, index) => (
                <CircleMetric
                    key={index}
                    label={metric.label}
                    value={metric.value}
                    color={metric.color}
                />
            ))}
        </div>
    )
}
