import { VeIcon, PhoneIcon } from 'assets'
import React, { useMemo } from 'react'
import { VeType } from 'storeTypes/vesTypes'
import { colors } from 'styles/colors'
import { DownloadableCard } from './DownloadableCard'
import { DownloadStatus } from 'types'
import { useDownloadVeData } from 'hooks'
import { Alert } from 'components'

type DownloadVeCardProps = {
    ve: VeType
    openClickHandler: (ve: VeType) => void
    downloadErrorMsg?: string
}

export const DownloadVeCard = ({
    ve,
    openClickHandler,
    downloadErrorMsg
}: DownloadVeCardProps) => {
    const villageIds = useMemo(
        () => ve.ve_villages?.map((village) => village.sf_id) || [],
        [ve.ve_villages]
    )
    const { downloadStatus, fetchVeData, isDownloadError } = useDownloadVeData(
        ve.code,
        villageIds
    )

    const clickHandler = () => {
        if (downloadStatus === DownloadStatus.NOT_DOWNLOADED) {
            fetchVeData()
        }
        if (downloadStatus === DownloadStatus.DOWNLOADED) {
            openClickHandler(ve)
        }
    }

    const PhoneLink = ({ phone }: { phone: string }) => {
        if (phone) {
            return (
                <a href={`tel:${phone}`} aria-label="phone-link">
                    <PhoneIcon
                        width="30"
                        height="30"
                        color={colors.blue}
                        fillColor={colors.blue}
                    />
                </a>
            )
        }

        return (
            <PhoneIcon
                width="30"
                height="30"
                color={colors.grey}
                fillColor={colors.grey}
            />
        )
    }
    const phone = (ve as VeType).phone_flotte
    const subtitles = (ve as VeType).current_villages
        ?.split(',')
        .map((village) => village.trim())

    return (
        <>
            <DownloadableCard
                key={ve.code}
                headerColor={colors.lighterBlue}
                borderBottomColor={colors.blue}
                icon={<VeIcon width="35" height="35" color={colors.blue} />}
                endIcon={<PhoneLink phone={phone} />}
                title={(ve as VeType).name}
                subtitles={subtitles}
                status={downloadStatus}
                clickHandler={() => clickHandler()}
                downloadCheckColor={colors.blue}
                downloadBackgroundColor={colors.lightBlue}
            />
            {isDownloadError && (
                <Alert type="floating" status={'error'}>
                    {downloadErrorMsg}
                </Alert>
            )}
        </>
    )
}
