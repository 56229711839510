import { amountFormatter } from 'helpers'
import { PaymentSummaryType } from '../types'

type ClientTransactionDataType = {
    allEnrollment: number
    balance: number
    last_voucher_date?: string
}

export const buildPaymentSummaryProps = (
    { allEnrollment, balance }: ClientTransactionDataType,
    lastVoucherDate: string
): PaymentSummaryType => ({
    allEnrollment,
    formattedAllEnrollment: amountFormatter(allEnrollment),
    balance,
    formattedBalance: amountFormatter(balance),
    paymentLeft: allEnrollment - balance,
    formattedPaymentLeft: amountFormatter(allEnrollment - balance),
    percentage: parseFloat(((balance / allEnrollment) * 100).toString()),
    lastVoucherDate
})
