import React from 'react'
import './textInput.scss'
import { TextTypes } from './textTypes'

export const TextInput: React.FC<TextTypes> = ({
    icon,
    type = 'text',
    validation,
    errorValidation,
    className,
    iconPos = 'right',
    ...props
}) => {
    const inputError = errorValidation ? 'generic--error-border' : ''
    const leftIconStyle = iconPos === 'left' ? 'left-icon-float' : ''
    const leftIconInputStyle = iconPos === 'left' ? 'left-icon-input' : ''
    return (
        <span className="textfield--parent">
            {validation && iconPos === 'left' && (
                <div
                    className={`textfield-input--icon ${leftIconStyle}`.trim()}
                >
                    {icon}
                </div>
            )}
            <input
                className={`textfield-input ${className} ${inputError} ${leftIconInputStyle}`.trim()}
                {...props}
                type={type}
                maxLength={props.maxLength}
            />
            {validation && iconPos === 'right' && (
                <div className="textfield-input--icon">{icon}</div>
            )}
        </span>
    )
}
