import { VeType } from 'storeTypes/vesTypes'
import { VillageType } from './appTypes'

export const extractVillages = (ves: VeType[]): Partial<VillageType>[] => {
    const villages = {} as Record<string, Partial<VillageType>>

    ves.forEach((ve) => {
        ve.ve_villages.forEach(({ name, code, sf_id: sfId }) => {
            if (villages[code]) {
                villages[code]?.ves?.push({
                    name: ve.name || '',
                    code: ve.code || ''
                })
            } else {
                villages[code] = {
                    name,
                    code,
                    sf_id: sfId,
                    ves: [
                        {
                            name: ve.name || '',
                            code: ve.code || ''
                        }
                    ]
                }
            }
        })
    })

    return Object.values(villages)
}
