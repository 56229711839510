import React from 'react'
import './counterButton.scss'

type CounterButtonProps = {
    onCountChange: (count: number) => void
    allowNegative?: boolean
    maxCount?: number
    count: number
}

export const CounterButton: React.FC<CounterButtonProps> = ({
    onCountChange,
    count = 0,
    allowNegative = false,
    maxCount
}) => {
    const decrement = () => {
        if (count === 0 && !allowNegative) {
            return
        }
        onCountChange(count - 1)
    }

    const increment = () => {
        if (maxCount && count === maxCount) return
        onCountChange(count + 1)
    }

    return (
        <div className="counter-pill">
            <div className="counter-pill-left-container" onClick={decrement}>
                <div data-testid="decrement-btn" className="incremental-btn">
                    -
                </div>
            </div>
            <span>{count}</span>
            <div className="counter-pill-right-container" onClick={increment}>
                <div data-testid="increment-btn" className="incremental-btn">
                    +
                </div>
            </div>
        </div>
    )
}
