import React, { useState, useEffect } from 'react'
import './layout.scss'
import { LayoutInterface } from './layoutTypes'

type ColConfig = {
    sm: 'col-sm'
    md: 'col-md'
    lg: 'col-lg'
    xl: 'col-xl'
    xxl: 'col-xxl'
    col: 'col'
}

export const Col: React.FC<LayoutInterface> = ({
    children,
    className = '',
    ...props
}) => {
    const [otherProps, setOtherProps] = useState({})
    let _props = {}
    const colConfig = {
        sm: 'col-sm',
        md: 'col-md',
        lg: 'col-lg',
        xl: 'col-xl',
        xxl: 'col-xxl',
        col: 'col'
    }

    const classNameConfig = () => {
        const updatedClassName = [className]
        for (const propsOption in props) {
            if (Object.prototype.hasOwnProperty.call(colConfig, propsOption)) {
                updatedClassName.push(
                    `${colConfig[propsOption as keyof ColConfig]}-${
                        props[propsOption as keyof ColConfig]
                    }`
                )
            } else {
                _props = {
                    ..._props,
                    [propsOption]: props[propsOption as keyof ColConfig]
                }
            }
        }
        return updatedClassName.join(' ').trim()
    }

    useEffect(() => {
        setOtherProps(_props)
    }, [])

    return (
        <div className={classNameConfig()} {...otherProps}>
            {children}
        </div>
    )
}
