import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, ProfileCardButton, Row, TopBar } from 'components'
import './profile.scss'
import {
    calcIcon,
    CardIcon,
    filledPhone,
    helpIcon,
    NetworkBarSvgs,
    VeIcon
} from 'assets'
import { colors } from 'styles/colors'
import { amountFormatter, constants, getConnectUser } from 'helpers'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { VeMetrics } from 'pages/profile/VeMetrics'
import { CSSTransition } from 'react-transition-group'
import { useBindDispatch } from 'hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'store/configStore'

export const VeProfile = () => {
    const { veCode = '' } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const nodeRef = useRef(null)

    const location = useLocation()
    const fromTab = (location?.search || '').split('from_tab=')[1]
    const { VillagesIcon } = NetworkBarSvgs

    const [animationList, setAnimationList] = useState(false)
    const [animate, setAnimate] = useState(false)

    const {
        getVeHandler,
        openVillagePageHandler,
        getVeDepositHandler,
        resetVeHandler,
        resetVeDepositHandler
    } = useBindDispatch()

    const { ve } = useSelector(
        ({ ve }: RootState) =>
            ve || {
                ve: {
                    code: '',
                    ve_villages: [],
                    name: '',
                    phone_flotte: '',
                    current_villages: ''
                }
            }
    )
    const { veDeposit } = useSelector(
        ({ storedVeDeposit }: RootState) =>
            storedVeDeposit || {
                veDeposit: {
                    deposits: []
                }
            }
    )

    const { country, role } = getConnectUser()

    useEffect(() => {
        setAnimate(true)
        if (!ve?.code) {
            getVeHandler(veCode)
        }
        if (!veDeposit?.deposits?.length) {
            getVeDepositHandler(veCode)
        }

        return () => {
            resetVeHandler()
            resetVeDepositHandler()
        }
    }, [])

    const veUser = {
        veVillages: ve?.ve_villages || [],
        country
    }

    const profileButtons = [
        {
            name: t('profile.calculator'),
            onClick: () => {},
            icon: calcIcon({})
        },
        {
            name: t('profile.cards'),
            onClick: () => {},
            icon: <CardIcon />
        },
        {
            name: t('profile.help'),
            onClick: () => {},
            icon: helpIcon({})
        }
    ]

    const cardClickHandler = (filterOptions: string[], clientTab: number) => {
        const hasMultipleVillages = ve?.ve_villages.length > 1

        openVillagePageHandler({
            filterOptions,
            clientTab,
            villageId: hasMultipleVillages ? '' : ve?.ve_villages[0].sf_id
        })

        console.log('handle navigate here')
    }

    const depositHandler = () => {}

    const backButtonHandler = () => {
        if (fromTab) {
            navigate(`/fc/village?from_tab=${fromTab}`)
        } else {
            navigate(-1)
        }
    }

    return (
        <CSSTransition
            unmountOnExit
            timeout={constants.ANIMATION_TIMEOUT}
            in={animate}
            classNames="generic"
            appear
            onEnter={() => setAnimationList(true)}
            nodeRef={nodeRef}
        >
            <div className="page-wrapper">
                <Container>
                    <Row>
                        <Col md={12}>
                            <TopBar
                                iconColor={colors.black}
                                transparentTopBar
                                back
                                backNavigation={backButtonHandler}
                            >
                                {t('profile.title')}
                            </TopBar>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="ve-profile--detail">
                                <VeIcon
                                    color={colors.blue}
                                    width="45"
                                    height="49"
                                />
                                <h2 className="ve-profile--name">{ve?.name}</h2>
                                <div className="ve-profile--contact">
                                    {filledPhone({ color: colors.blue })}
                                    <p className="ve-profile--phone">
                                        {amountFormatter(
                                            ve?.phone_flotte,
                                            ''
                                        ).trim()}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <ProfileCardButton cardButtons={profileButtons} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="ve-profile--village-parent">
                                <VillagesIcon color={colors.primary} />
                                <p className="ve-profile--village-text">
                                    {ve?.current_villages}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <VeMetrics
                                animationList={animationList}
                                veMetrics={ve || {}}
                                veDeposit={veDeposit}
                                veUser={veUser}
                                canViewDeposits={true}
                                canViewDepositGap={true}
                                cardClickHandler={cardClickHandler}
                                depositHandler={depositHandler}
                                userRole={role}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </CSSTransition>
    )
}
