import { Dispatch } from 'react'
import { VeSearchActionEnum, VeSearchActionType } from 'storeTypes'
import { VeType } from 'storeTypes/vesTypes'

export const saveVeSearchResults =
    (results: VeType[]) => (dispatch: Dispatch<VeSearchActionType>) =>
        dispatch({
            type: VeSearchActionEnum.SAVE_VE_SEARCH_RESULTS,
            payload: results
        })
