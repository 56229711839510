import React from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom'
import { FcHome } from 'fc/pages/home/FcHome'
import { FcProfile, FcVillage, VillageProfile, VeProfile } from 'fc/pages'
import { FcSearchResults } from 'fc/pages/search/FcSearchResults'

const ConnectRoutes = () => {
    return (
        <>
            <Route path="/" element={<Navigate replace to="/fc/home" />} />
            <Route path="/fc" element={<Outlet />}>
                <Route path="home" element={<FcHome />} />
                <Route path="profile" element={<FcProfile />} />
                <Route path="village" element={<Outlet />}>
                    <Route path="" element={<FcVillage />} />
                    <Route
                        path=":veCode/profile"
                        element={<VillageProfile />}
                    />
                    <Route path="ve/:veCode/profile" element={<VeProfile />} />
                </Route>
                <Route path="village" element={<FcVillage />} />
                <Route path="ve-search/results" element={<FcSearchResults />} />
            </Route>
        </>
    )
}

export default ConnectRoutes
