import React from 'react'
import { ProgressBar } from 'components'

interface MetricsProgressProps {
    current: number
    target: number
    ranges?: {
        low: number
        high: number
    }
    extraClasses?: string
}

const isTargetValid = (value: number | null | undefined) => value !== 0 && value

export const MetricsProgress = ({
    current,
    target,
    ranges,
    extraClasses = ''
}: MetricsProgressProps) => {
    const displayProgressBar = isTargetValid(target)
    const progressValueClass = current >= target ? 'blue' : ''

    if (displayProgressBar) {
        return (
            <div className="target-progressbar">
                <ProgressBar
                    percentage={(current / target) * 100}
                    size="large"
                    ranges={ranges}
                />
                <div className="progress-numbers">
                    <span
                        className={`progress-value ${progressValueClass}`}
                        aria-label="progress-current-value"
                    >
                        <p className="progress-value">{current}</p>
                    </span>
                    <span
                        className={`progress-max ${progressValueClass}`}
                        aria-label="progress-max-value"
                    >
                        <p className="progress-value">{target}</p>
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className="target-progressbar">
            <h1
                aria-label="progress-current-value"
                className={`client-number--content client-number--num ${extraClasses}`}
            >
                {current || 0}
            </h1>
        </div>
    )
}
