import React from 'react'
import {
    Arrow195DegreesLeftIcon,
    Arrow45DegreesRightIcon,
    MyAgroLogo
} from 'assets'
import './deposit.scss'
import { DepositDetailsType, DepositSource } from 'storeTypes'
import { amountFormatter } from 'helpers'
import { TFunction } from 'react-i18next'
import transactionSourceIcons from 'assets/svg/transactionSourceIcons'

const { OrangeMoneyPaymentIcon, WavePaymentIcon } = transactionSourceIcons

type SingleDepositType = DepositDetailsType & {
    t: TFunction<'translation', undefined>
}

const getDepositIcon = (source: DepositSource) => {
    if (source === DepositSource.ORANGE_MONEY) {
        return <OrangeMoneyPaymentIcon />
    }
    if (source === DepositSource.WAVE) return <WavePaymentIcon />

    return <MyAgroLogo size={24} />
}

export const SingleDeposit = ({
    deposit_date: depositDate,
    amount,
    reference_code: referenceCode,
    source,
    t
}: SingleDepositType) => {
    return (
        <div className="single-deposit" aria-label="single-deposit">
            <div className="single-deposit--date">
                <p className="single-deposit--date-text">
                    {t('dateMonthYear', {
                        date: new Date(depositDate)
                    })}
                </p>
            </div>
            <div className="single-deposit--content">
                <div className="single-deposit--icon">
                    {amount > 0 ? (
                        <div
                            className="single-deposit--icon-container"
                            aria-label="positive-arrow"
                        >
                            <Arrow45DegreesRightIcon />
                        </div>
                    ) : (
                        <div
                            className="single-deposit--icon-container"
                            aria-label="negative-arrow"
                        >
                            <Arrow195DegreesLeftIcon />
                        </div>
                    )}
                </div>
                <div className="single-deposit--amount">
                    <h2
                        className={`single-deposit--amount-text-${
                            amount > 0 ? 'positive' : 'negative'
                        }`}
                    >
                        {amountFormatter(amount)}
                    </h2>
                    <p className="single-deposit--amount-reference">
                        {referenceCode}
                    </p>
                </div>
                <div className="payment-icon-container">
                    {getDepositIcon(source)}
                </div>
            </div>
        </div>
    )
}
