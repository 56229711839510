import { TextInput } from 'components/textInput/TextInput'
import React from 'react'

interface TargetListSearchInterface {
    onSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void
    searchValue: string
    t: (key: string) => string
    searchIcon?: JSX.Element
    searchIconPos?: 'left' | 'right'
    searchPlaceholder?: string
}

export const TargetListSearch = ({
    onSearchInput,
    searchValue,
    t,
    searchIcon,
    searchIconPos,
    searchPlaceholder
}: TargetListSearchInterface) => {
    return (
        <TextInput
            placeholder={searchPlaceholder || t('targetList.searchPlaceholder')}
            onChange={(e) => onSearchInput(e)}
            value={searchValue}
            data-testid="search-input"
            id="client-list-search"
            className="target-list--search-input"
            icon={searchIcon}
            iconPos={searchIconPos}
            validation={!!searchIcon}
        />
    )
}
